import React, { useEffect, useRef, useState } from "react";
import { ExInput, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import moment from "moment";
import { SuccessStatusCode } from "../../../util/constants";
import {
  rejectComplain,
  solveComplain
} from "../../../redux/slice/complainSlice";

const mapStatusToKo = {
  pending: "미승인",
  rejected: "거절",
  approved: "승인",
  completed: "완료"
};

const ReasonModal = (props) => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-4 col-md-6 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  {/* <h2 className="text-theme m0">이의 상세</h2> */}
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"사유"}
                    value={dialogueData || ""}
                    readOnly={true}
                  />
                </div>
              </div>

              {/* <div className="col-12 d-flex justify-content-end align-items-center gap-2">
                <Button
                  className={`bg-gray text-light`}
                  text={`취소`}
                  type={`button`}
                  onClick={() => dispatch(closeDialog())}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReasonModal;
