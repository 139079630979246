import React, { useRef, useState } from "react";
import { ExInput, SelectV2, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import { createNotification } from "../../../redux/slice/notificationSlice";
import { SuccessStatusCode } from "../../../util/constants";

const types = [
  {
    value: "all",
    name: "전체"
  },
  {
    value: "bartender",
    name: "바텐더"
  },
  {
    value: "user",
    name: "사용자"
  }
];
const SendModal = (props) => {
  const dispatch = useDispatch();
  const loadingRef = useRef(false);
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [type, setType] = useState("all");
  const [title, setTitle] = useState();
  const [content, setContent] = useState();

  const [error, setError] = useState({
    type: "",
    title: "",
    content: ""
  });

  const handleSubmit = async () => {
    try {
      if (!type || !title || !content) {
        let error = {};
        if (!type) error.type = "Type is Required";
        if (!title) error.title = "제목은 필수입니다";
        if (!content) error.content = "내용은 필수입니다";

        return setError({ ...error });
      } else {
        loadingRef.current = true;
        const body = {
          sendType: type,
          title: title || "",
          content: content || ""
        };
        console.log("body", body);

        const res = await dispatch(createNotification(body));
        console.log("res", res);
        if (res.payload?.statusCode === SuccessStatusCode) {
          loadingRef.current = false;
          dispatch(closeDialog());
          props.refetch && props.refetch();
        }
      }
    } catch (err) {
      loadingRef.current = false;
      console.log("err.message", err);
    }
  };

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-11 col-md-6 col-xl-4">
            <div class="mainDiaogBox">
              <div class="row formHead align-items-center justify-content-between">
                <div className="col-8">
                  <h2 className="text-theme m0">PUSH 알림 발송</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row formBody align-items-start">
                <div className="row">
                  <div className="col-12 col-md-8 inputData">
                    <label>회원 타입</label>
                    <SelectV2
                      id={`type`}
                      option={types}
                      value={type}
                      onChange={(value) => setType(value)}
                      className={`inputSelectTime`}
                      errorMessage={error.type}
                      btnClass={`mt-0`}
                      angle={true}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"알림 메시지 제목"}
                    value={title}
                    errorMessage={error.title}
                    onPaste={(event) => {
                      const pastedData = event.clipboardData.getData("Text");
                      if (pastedData.length > 40) {
                        event.preventDefault();
                        setTitle(pastedData.slice(0, 40));
                      } else {
                        setTitle(pastedData);
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value.length <= 40) {
                        setTitle(e.target.value);
                      }
                      if (!e.target.value) {
                        setError({
                          ...error,
                          title: "제목은 필수입니다"
                        });
                      } else {
                        setError({
                          ...error,
                          title: ""
                        });
                      }
                    }}
                    placeholder={"내용을 입력하세요. (공백 포함 40자 이내)"}
                  />
                </div>
                <div className="col-12">
                  <Textarea
                    value={content}
                    name={"content-push"}
                    errorMessage={error.content}
                    classError={"errorMessage"}
                    onPaste={(event) => {
                      const pastedData = event.clipboardData.getData("Text");
                      if (pastedData.length > 140) {
                        event.preventDefault();
                        setContent(pastedData.slice(0, 140));
                      } else {
                        setContent(pastedData);
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value.length <= 140) {
                        setContent(e.target.value);
                      }
                      if (!e.target.value) {
                        setError({
                          ...error,
                          content: "내용은 필수입니다"
                        });
                      } else {
                        setError({
                          ...error,
                          content: ""
                        });
                      }
                    }}
                    label={`알림 메시지 내용`}
                    placeholder={`내용을 입력하세요. (공백 포함 140자 이내)`}
                  />
                </div>
              </div>

              <div className="col-12 d-flex align-items-center justify-content-end gap-2">
                <Button
                  className={`bg-gray text-light`}
                  text={`취소`}
                  type={`button`}
                  onClick={() => dispatch(closeDialog())}
                />
                <Button
                  type={`submit`}
                  className={` text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`등록`}
                  disabled={loadingRef.current}
                  onClick={() => handleSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendModal;
