/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../component/api/axiosApi";
import { DangerRight, Success } from "../../component/api/toastServices";
import { SuccessStatusCode } from "../../util/constants";
import { genQueryString } from "../../util/fuction";

const initialState = {
  isLoading: false,
  isSkeleton: false,
  notice: [],
  total: null
};

export const getAllNotice = createAsyncThunk(
  "admin/notice/getAll",
  async (payload) => {
    return apiInstance.get(genQueryString(`admin/notices`, payload));
  }
);

export const addNotice = createAsyncThunk(
  "admin/notice/create",
  async (payload) => {
    return apiInstance.post(`admin/notices`, payload);
  }
);

export const updateNotice = createAsyncThunk(
  "admin/notice/update",
  async (payload) => {
    return apiInstance.put(`admin/notices/${payload.id}`, payload.body);
  }
);

export const deleteNotice = createAsyncThunk(
  "admin/notice/delete",
  async (id) => {
    const res = await apiInstance.delete(`admin/notices/${id}`);
    return {
      data: res,
      id
    };
  }
);

const noticeSlice = createSlice({
  name: "noticeSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllNotice.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllNotice.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.notice = action.payload.data;
      state.total = action.payload.count;
    });

    builder.addCase(getAllNotice.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(addNotice.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addNotice.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        state.total += 1;
        Success("추가되었습니다.");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(addNotice.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateNotice.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateNotice.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        Success("수정되었습니다.");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(updateNotice.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteNotice.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteNotice.fulfilled, (state, action) => {
      if (action?.payload?.data?.statusCode === SuccessStatusCode) {
        state.notice = state.notice.filter(
          (item) => item?.noticeId !== action?.payload?.id
        );

        state.total -= 1;
        Success("삭제되었습니다.");
      }
      state.isLoading = false;
    });
    builder.addCase(deleteNotice.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});
export default noticeSlice.reducer;
