import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSalonSchedule } from "../../../redux/slice/salonSlice";
import { useLocation } from "react-router-dom";
import Title from "../../extras/Title";
import Table from "../../extras/Table";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import ScheduleDialogue from "./ScheduleDialogue";
import moment from "moment";

const sortOrder = [1, 2, 3, 4, 5, 6, 0];

function sortData(data) {
  console.log("data", data);
  const sortedData = [...data].sort((a, b) => {
    const indexA = sortOrder.indexOf(a.day);
    const indexB = sortOrder.indexOf(b.day);
    return indexA - indexB;
  });
  return sortedData;
}

const mapDayToKo = {
  1: "월요일",
  2: "화요일",
  3: "수요일",
  4: "목요일",
  5: "금요일",
  6: "토요일",
  0: "일요일"
};

const SalonSchedule = () => {
  const { salonSchedule } = useSelector((state) => state.salon);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const state = useLocation();

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (state?.state?.row?.storeId) {
      dispatch(getSalonSchedule(state?.state?.row?.storeId));
    }
  }, [dispatch, state?.state?.row?.storeId]);

  useEffect(() => {
    setData(sortData(salonSchedule || []));
  }, [salonSchedule]);

  const handleOpenEditModal = (row) => {
    dispatch(
      openDialog({
        type: "schedule",
        data: { data: row, salonId: state?.state?.row?.storeId }
      })
    );
  };

  const scheduleTable = [
    {
      Header: "요일",
      Cell: ({ row }) => (
        <span
          className="text-capitalize fw-bold"
          style={{ minWidth: "500px !important" }}
        >
          {row?.day || row?.day === 0 ? mapDayToKo[row?.day] : ""}
        </span>
      )
    },
    {
      Header: "영업 시작 시간",
      Cell: ({ row }) => (
        <span className="text-lowercase">
          {row?.openAt
            ? moment.utc(`2025-02-15T${row?.openAt}`).format("hh:mm a")
            : ""}
        </span>
      )
    },

    {
      Header: "영업 종료 시간",
      Cell: ({ row }) => (
        <span className="text-lowercase">
          {row?.closeAt
            ? moment.utc(`2025-02-15T${row.closeAt}`).format("hh:mm a")
            : ""}
        </span>
      )
    },
    {
      Header: "수정",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenEditModal(row)}
        >
          수정
        </button>
      )
    }
  ];

  return (
    <div className="mainCategory">
      <Title
        name={`스토어바 ${state?.state?.row?.storeName || ""}역점 영업 시간`}
      />
      <p className="titleTable mb-2">사용자에게 노출될 영업 시간 입니다.</p>
      <div>
        <Table
          data={data}
          mapData={scheduleTable}
          serverPerPage={rowsPerPage}
          className={"tableSchedule"}
          Page={page}
        />
      </div>
      {dialogue && dialogueType === "schedule" && (
        <ScheduleDialogue setData={setData} data={data} />
      )}
    </div>
  );
};

export default SalonSchedule;
