/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-dupe-keys */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "../../extras/Button";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import {
  addService,
  updateService,
  getCatSubCat,
  getAllProducts
} from "../../../redux/slice/serviceSlice";
import { getAllCategory } from "../../../redux/slice/categorySlice";
import { DangerRight } from "../../api/toastServices";
import { ExInput, SelectV2 } from "../../extras/Input";
import { uploadFile } from "../../../redux/api";
import {
  convertStringToTime,
  convertToTime,
  getImageSize
} from "../../../util/fuction";
import { useMemo } from "react";

const hours = [
  {
    name: 0,
    value: 0
  },
  {
    name: 1,
    value: 1
  },
  {
    name: 2,
    value: 2
  }
];

const minutes = [
  {
    value: 0,
    name: 0
  },
  {
    value: 30,
    name: 30
  }
];

const hoursFull = new Array(25).fill(1).map((_, index) => ({
  value: index,
  name: index
}));

const minutesFull = new Array(60).fill(1).map((_, index) => ({
  value: index,
  name: index
}));

const errorSetMenu = {
  optionName: "상품을 선택하세요",
  optionPrice: "할인가격을 입력해주세요",
  optionPaymentAmount: "정산금액 입력해주세요",
  optionBasePrice: "가격 입력해주세요",
  optionAvailableReserTimeHour: "예약 가능 시간을 입력하세요",
  name: "메뉴 이름을 입력하세요",
  order: "표시 순서를 입력하세요",
  image: "사진을 선택하세요"
};

const errorSingle = {
  optionName: "상품을 선택하세요",
  optionPrice: "할인가격을 입력해주세요",
  optionPaymentAmount: "정산금액 입력해주세요",
  optionBasePrice: "가격 입력해주세요",
  order: "표시 순서를 입력하세요",
  image: "사진을 선택하세요"
};

const ServiceDialogue = (props) => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { products } = useSelector((state) => state.service);

  const [type, setType] = useState("set");
  const [order, setOrder] = useState("");
  const [day, setDay] = useState(false);
  const [hourStart, setHourStart] = useState(0);
  const [minuteStart, setMinuteStart] = useState(0);
  const [hourEnd, setHourEnd] = useState(0);
  const [minuteEnd, setMinuteEnd] = useState(0);
  const [sideDishSelect, setSideDishSelect] = useState([]);

  const [description, setDescription] = useState("");
  const [options, setOptions] = useState([
    {
      name: "",
      price: "",
      paymentAmount: "",
      basePrice: "",
      discount: "",
      availableReserTimeHour: 0,
      availableReserTimeMinute: 0,
      stock: ""
    }
  ]);
  const [optionSingle, setOptionSingle] = useState([
    {
      name: "",
      price: "",
      paymentAmount: "",
      basePrice: "",
      discount: "",
      availableReserTimeHour: 0,
      availableReserTimeMinute: 0,
      stock: ""
    }
  ]);
  const [name, setName] = useState();
  const [image, setImage] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0
  });
  const [imageDetail, setImageDetail] = useState("");
  const [imageDetailPath, setImageDetailPath] = useState("");
  const [imageDetailSize, setImageDetailSize] = useState({
    width: 0,
    height: 0
  });
  const [mongoId, setMongoId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState({
    name: "",
    options: [],
    optionSingle: [],
    image: "",
    imagePath: "",
    duration: "",
    categoryy: "",
    order: "",
    day: "",
    description: ""
  });

  const fetchData = () => {
    dispatch(getAllProducts(props.storeId));
  };

  useEffect(() => {
    fetchData();
  }, [props.storeId]);

  const productSetList = useMemo(() => {
    return products
      .filter((item) =>
        ["WHISKY", "BEER", "WINE", "CHAMPAGNE"].includes(item.category)
      )
      .map((item) => ({
        value: item.id,
        name: item.name
      }));
  }, [products]);

  const productSingleList = useMemo(() => {
    return products.map((item) => ({
      value: item.id,
      name: item.name
    }));
  }, [products]);

  const productSideDishList = useMemo(() => {
    return products
      .filter((item) => ["SIDE_DISH"].includes(item.category))
      .map((item) => ({
        value: item.id,
        name: item.name
      }));
  }, [products]);

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData?.id);
      setName(dialogueData?.name);
      setDescription(dialogueData?.description);
      setOrder(dialogueData?.order);
      setDay(dialogueData?.isDaytime);
      if (dialogueData?.type === "SET") {
        setType("set");
        const optionsMap = [...(dialogueData?.items || [])]
          .filter((item) => item.category !== "SIDE_DISH")
          .map((item) => {
            const { hours, minutes } = item.reservationAvailability
              ? convertToTime(item.reservationAvailability)
              : { hours: 0, minutes: 0 };

            return {
              id: item.id,
              name: item.productId,
              price: item.discountPrice,
              paymentAmount: item.settlementAmount,
              basePrice: item.salePrice || 0,
              discount:
                item.discountRate || item.discountRate === 0
                  ? item.discountRate
                  : "",
              availableReserTimeHour: hours,
              availableReserTimeMinute: minutes,
              stock: ""
            };
          });
        const optionsSidDish = [...(dialogueData?.items || [])]
          .filter((item) => item.category === "SIDE_DISH")
          .map((item) => item.productId);
        setOptions([...optionsMap]);
        setSideDishSelect([...optionsSidDish]);
      } else if (dialogueData?.type === "SINGLE") {
        setType("single");
        const optionSingleMap = [...(dialogueData?.items || [])].map((item) => {
          const { hours, minutes } = item.reservationAvailability
            ? convertToTime(item.reservationAvailability)
            : { hours: 0, minutes: 0 };

          return {
            id: item.id,
            name: item.productId,
            price: item.discountPrice,
            paymentAmount: item.settlementAmount,
            basePrice: item.salePrice || 0,
            discount: item.discountRate || 0,
            availableReserTimeHour: hours,
            availableReserTimeMinute: minutes,
            stock: ""
          };
        });
        setOptionSingle([...optionSingleMap]);
      }
      const { hours: startHour, minutes: startMinute } = dialogueData?.startTime
        ? convertStringToTime(dialogueData?.startTime)
        : { hours: 0, minutes: 0 };
      setHourStart(startHour);
      setMinuteStart(startMinute);
      const { hours: endHour, minutes: endMinute } = dialogueData?.endTime
        ? convertStringToTime(dialogueData?.endTime)
        : { hours: 0, minutes: 0 };
      setHourEnd(endHour);
      setMinuteEnd(endMinute);
      setImage(dialogueData?.thumbnail || "");
      setImagePath(dialogueData?.thumbnail || "");
      if (dialogueData?.thumbnail) {
        getImageSize(dialogueData?.thumbnail)
          .then(({ width, height }) => {
            setImageSize({
              width,
              height
            });
          })
          .catch((error) => {
            console.error("Error loading image:", error);
          });
      }
      setImageDetail(dialogueData?.thumbnailDetail || "");
      setImageDetailPath(dialogueData?.thumbnailDetail || "");
      if (dialogueData?.thumbnailDetail) {
        getImageSize(dialogueData?.thumbnailDetail)
          .then(({ width, height }) => {
            setImageDetailSize({
              width,
              height
            });
          })
          .catch((error) => {
            console.error("Error loading image:", error);
          });
      }
    }
  }, [dialogueData]);

  const handleCheckDeleteOption = () => {
    const prevOptions = dialogueData.items || [];
    const currOptions = [...options];

    const deleteOptions = prevOptions
      .filter(
        (prevOption) =>
          !currOptions.find((item) => item.id === prevOption.id) &&
          !sideDishSelect.includes(prevOption.productId)
      )
      .map((item) => ({
        ...item,
        action: "DELETE",
        discountRate: Number(item.discountRate)
      }));
    return deleteOptions;
  };

  const handleValidateOptions = () => {
    let errors = [];

    options.forEach((option, index) => {
      if (!option.name) {
        errors[index] = {
          ...(errors[index] || {}),
          name: errorSetMenu.optionName
        };
      }
      if (!option.basePrice) {
        errors[index] = {
          ...(errors[index] || {}),
          price: errorSetMenu.optionBasePrice
        };
      }

      if (!option.paymentAmount && day) {
        errors[index] = {
          ...(errors[index] || {}),
          paymentAmount: errorSetMenu.optionPaymentAmount
        };
      }
      if (!option.price && day) {
        errors[index] = {
          ...(errors[index] || {}),
          price: errorSetMenu.optionPrice
        };
      }
      if (!option.availableReserTimeHour && !option.availableReserTimeMinute) {
        errors[index] = {
          ...(errors[index] || {}),
          availableReserTimeHour: errorSetMenu.optionAvailableReserTimeHour
        };
      }
    });

    if (errors.length > 0) {
      return { errorsList: errors, optionsList: [] };
    } else {
      const items = options.map((option) => {
        const productDetail = products.find(
          (item) => item.id === Number(option.name)
        );

        return {
          ...(option?.id ? { id: option.id } : {}),
          productId: Number(option.name || 0),
          name: productDetail.name || "",
          discountPrice: day ? Number(option.price) : Number(option.basePrice),
          settlementAmount: Number(option.paymentAmount),
          reservationAvailability:
            Number(option.availableReserTimeHour) * 60 +
            Number(option.availableReserTimeMinute),
          stock: productDetail.stock || 0,
          basePrice: Number(option.basePrice),
          discountRate: day ? Number(option.discount || 0) : 0,
          isDaytime: true,
          startTime: "05:00",
          endTime: "05:00",
          ...(option?.id ? { action: "UPDATE" } : {})
        };
      });
      const itemsSideDish = sideDishSelect.map((option) => {
        const productDetail = products.find(
          (item) => item.id === Number(option)
        );

        const prevOptions = dialogueData?.items || [];

        const preProductDetail = prevOptions.find(
          (item) => item.productId === Number(option)
        );

        if (preProductDetail) {
          return {
            ...(preProductDetail?.id ? { id: preProductDetail.id } : {}),
            productId: Number(option || 0),
            name: productDetail.name || "",
            discountPrice: 0,
            settlementAmount: 0,
            reservationAvailability: 0,
            stock: 0,
            basePrice: 0,
            discountRate: 0,
            isDaytime: true,
            startTime: "05:00",
            endTime: "05:00",
            ...(preProductDetail?.id ? { action: "UPDATE" } : {})
          };
        }
        return {
          ...(option?.id ? { id: option.id } : {}),
          productId: Number(option || 0),
          name: productDetail.name || "",
          discountPrice: 0,
          settlementAmount: 0,
          reservationAvailability: 0,
          stock: 0,
          basePrice: Number(option.basePrice),
          discountRate: 0,
          isDaytime: true,
          startTime: "05:00",
          endTime: "05:00",
          ...(option?.id ? { action: "UPDATE" } : {})
        };
      });
      return {
        errorsList: [],
        optionsList: [
          ...items,
          ...itemsSideDish,
          ...(mongoId ? handleCheckDeleteOption() : [])
        ]
      };
    }
  };

  const handleValidateOptionSingle = () => {
    let errors = [];

    optionSingle.forEach((option, index) => {
      if (!option.name) {
        errors[index] = {
          ...(errors[index] || {}),
          name: errorSingle.optionName
        };
      }
      if (!option.basePrice) {
        errors[index] = {
          ...(errors[index] || {}),
          price: errorSingle.optionBasePrice
        };
      }

      if (!option.paymentAmount && day) {
        errors[index] = {
          ...(errors[index] || {}),
          paymentAmount: errorSingle.optionPaymentAmount
        };
      }
      if (!option.price && day) {
        errors[index] = {
          ...(errors[index] || {}),
          price: errorSingle.optionPrice
        };
      }
    });

    if (errors.length > 0) {
      return { errorsList: errors, optionsList: [] };
    } else {
      const items = optionSingle.map((option) => {
        const productDetail = products.find(
          (item) => item.id === Number(option.name)
        );
        return {
          ...(option?.id ? { id: option.id } : {}),
          productId: Number(option.name || 0),
          name: productDetail.name,
          discountPrice: day ? Number(option.price) : Number(option.basePrice),
          settlementAmount: Number(option.paymentAmount),
          reservationAvailability:
            Number(option.availableReserTimeHour) * 60 +
            Number(option.availableReserTimeMinute),
          stock: productDetail.stock || 0,
          basePrice: Number(option.basePrice),
          discountRate: day ? Number(option.discount || 0) : 0,
          isDaytime: true,
          startTime: "05:00",
          endTime: "05:00",
          ...(option?.id ? { action: "UPDATE" } : {})
        };
      });
      return { errorsList: [], optionsList: items };
    }
  };

  const handleDataAdd = () => {
    if (type === "set") {
      const { errorsList, optionsList } = handleValidateOptions();
      if (
        !name ||
        !order ||
        errorsList.length > 0 ||
        // !image?.length === 0 ||
        // !imagePath ||
        !imageDetail?.length === 0 ||
        !imageDetailPath
      ) {
        let error = { options: errorsList };
        if (!name) error.name = errorSetMenu.name;
        if (!order) error.order = errorSetMenu.order;
        // if (!image?.length === 0 || !imagePath)
        //   error.image = errorSetMenu.image;
        if (!imageDetail?.length === 0 || !imageDetailPath)
          error.imageDetail = errorSetMenu.image;
        setError({ ...error });
        return null;
      } else {
        return {
          isDaytime: day,
          startTime: `${hourStart}:${minuteStart}`,
          endTime: `${hourEnd}:${minuteEnd}`,
          name: name,
          description: description,
          items: optionsList,
          thumbnail: "Thumbnail",
          order: Number(order),
          type: "SET"
        };
      }
    }

    if (type === "single" || type === "sideDish") {
      const { errorsList, optionsList } = handleValidateOptionSingle();
      if (
        (!name && type === "set") ||
        !order ||
        errorsList.length > 0
        // ||
        // !image?.length === 0 ||
        // !imagePath
      ) {
        let error = { optionSingle: errorsList };
        if (!name && type === "set") error.name = "Menu name is Required";
        if (!order) error.order = errorSingle.order;
        // if (!image?.length === 0 || !imagePath) error.image = errorSingle.image;
        setError({ ...error });
        return null;
      } else {
        const productName = products.find(
          (item) => item.id === optionsList?.[0]?.productId
        )?.name;
        return {
          isDaytime: day,
          startTime: `${hourStart}:${minuteStart}`,
          endTime: `${hourEnd}:${minuteEnd}`,
          name: productName || "",
          description: description,
          items: optionsList,
          thumbnail: "Thumbnail",
          order: Number(order),
          type: type === "single" ? "SINGLE" : "SIDE_DISH"
        };
      }
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    try {
      const body = handleDataAdd();
      if (body) {
        let thumbnail = "";
        let thumbnailDetail = "";
        if (image) {
          if (typeof image === "string") {
            thumbnail = image;
          } else {
            const formDataImage = new FormData();
            formDataImage.append("file", image);
            const imageRes = await uploadFile(formDataImage);
            thumbnail = imageRes.data.filepath;
          }
        }
        if (imageDetail) {
          if (typeof imageDetail === "string") {
            thumbnailDetail = imageDetail;
          } else {
            const formDataImage = new FormData();
            formDataImage.append("file", imageDetail);
            const imageRes = await uploadFile(formDataImage);
            thumbnailDetail = imageRes.data.filepath;
          }
        }
        if (mongoId) {
          let payload = {
            body: { ...body, thumbnail, thumbnailDetail },
            id: mongoId
          };
          await dispatch(updateService(payload));
          dispatch(closeDialog());
          setTimeout(() => {
            props.refecth && props.refecth();
          }, 0);
        } else {
          await dispatch(
            addService({
              storeId: props.storeId,
              body: { ...body, thumbnail, thumbnailDetail }
            })
          );
          dispatch(closeDialog());
          setTimeout(() => {
            props.refecth && props.refecth();
          }, 0);
        }
      }
      if (mongoId) {
        // let payload = {
        //   body,
        //   id: mongoId
        // };
        // dispatch(updateService(payload));
        // dispatch(closeDialog());
      } else {
        // dispatch(addService({ storeId: props.storeId, body: formData }));
        // dispatch(closeDialog());
      }
      // dispatch(closeDialog());
    } catch (err) {
      console.log("err.message", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImage = (e) => {
    if (!e.target.files) {
      setError((prevErrors) => ({
        ...prevErrors,
        image: "Image is Required"
      }));
    }
    setImage(e.target.files[0]);
    getImageSize(e.target.files[0])
      .then(({ width, height }) => {
        setImageSize({
          width,
          height
        });
      })
      .catch((error) => {
        console.error("Error loading image:", error);
      });
    setImagePath(URL.createObjectURL(e.target.files[0]));
    setError((prevErrors) => ({
      ...prevErrors,
      image: ""
    }));
  };

  const handleImageDetail = (e) => {
    if (!e.target.files) {
      setError((prevErrors) => ({
        ...prevErrors,
        image: "Image is Required"
      }));
    }
    setImageDetail(e.target.files[0]);
    getImageSize(e.target.files[0])
      .then(({ width, height }) => {
        setImageDetailSize({
          width,
          height
        });
      })
      .catch((error) => {
        console.error("Error loading image:", error);
      });
    setImageDetailPath(URL.createObjectURL(e.target.files[0]));
    setError((prevErrors) => ({
      ...prevErrors,
      imageDetail: ""
    }));
  };

  const handleAddOption = () => {
    setOptions([
      ...options,
      {
        name: "",
        price: "",
        paymentAmount: "",
        basePrice: "",
        discount: "",
        availableReserTimeHour: 0,
        availableReserTimeMinute: 0,
        stock: ""
      }
    ]);
  };

  const handleRemoveOption = (index) => {
    let newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions([...newOptions]);
  };

  const handleChangeOptionField = (field, value, index, fieldName = "") => {
    let newOptions = [...options];
    let newOptionError = error.options;
    const itemProduct =
      field === "name"
        ? products.find((item) => item.id === Number(value))
        : null;
    newOptions[index] = {
      ...newOptions[index],
      ...(field === "name"
        ? {
            basePrice: itemProduct?.salePrice,
            paymentAmount: itemProduct.salePrice
          }
        : {}),
      // ...(field === "basePrice"
      //   ? {
      //       paymentAmount: value
      //     }
      //   : {}),
      // ...(field === "price" && day
      //   ? {
      //       paymentAmount: value
      //     }
      //   : {}),
      [field]: value
    };
    if (
      field === "availableReserTimeMinute" ||
      field === "availableReserTimeHour"
    ) {
      const cobinedValue =
        field === "availableReserTimeMinute"
          ? newOptions[index].availableReserTimeHour
          : newOptions[index].availableReserTimeMinute;
      newOptionError[index] = {
        ...(newOptionError?.[index] || {}),
        [field === "availableReserTimeMinute"
          ? "availableReserTimeHour"
          : field]: !value && !cobinedValue ? errorSetMenu[fieldName] : ""
      };
    } else {
      newOptionError[index] = {
        ...(newOptionError?.[index] || {}),
        ...(field === "name" ? { price: "" } : {}),
        [field === "availableReserTimeMinute"
          ? "availableReserTimeHour"
          : field]: value ? "" : errorSetMenu[fieldName]
      };
    }

    if (field === "price") {
      const valueDiscount =
        Number(value) === 0
          ? 100
          : Number(value) > Number(newOptions[index].basePrice)
          ? 0
          : (
              (1 - Number(value) / Number(newOptions[index].basePrice)) *
              100
            ).toFixed(0);
      newOptions[index] = {
        ...newOptions[index],
        discount: valueDiscount
      };
    }
    if (field === "name") {
      // const valueDiscount =
      //   Number(newOptions[index].price) === 0
      //     ? 100
      //     : Number(newOptions[index].price) >
      //       Number(newOptions[index].basePrice)
      //     ? 0
      //     : (
      //         (1 -
      //           Number(newOptions[index].price) /
      //             Number(newOptions[index].basePrice)) *
      //         100
      //       ).toFixed(0);
      newOptions[index] = {
        ...newOptions[index],
        discount: "",
        price: ""
      };
    }

    setError({ ...error, options: newOptionError });
    setOptions(newOptions);
  };

  const handleChangeSingleOptionField = (field, value, fieldName = "") => {
    let newOptions = [...optionSingle];
    let newOptionError = error.optionSingle || [];
    const itemProduct =
      field === "name"
        ? products.find((item) => item.id === Number(value))
        : null;
    newOptions[0] = {
      ...newOptions[0],
      ...(field === "name"
        ? {
            basePrice: itemProduct.salePrice,
            paymentAmount: itemProduct.salePrice
          }
        : {}),
      // ...(field === "price" && day
      //   ? {
      //       paymentAmount: value
      //     }
      //   : {}),
      [field]: value
    };
    if (field === "price") {
      const valueDiscount =
        Number(value) === 0
          ? 100
          : Number(value) > Number(newOptions[0].basePrice)
          ? 0
          : (
              (1 - Number(value) / Number(newOptions[0].basePrice)) *
              100
            ).toFixed(2);
      newOptions[0] = {
        ...newOptions[0],
        discount: valueDiscount
      };
    }
    if (field === "name") {
      // const valueDiscount =
      //   Number(newOptions[0].price) === 0
      //     ? 100
      //     : Number(newOptions[0].price) > Number(newOptions[0].basePrice)
      //     ? 0
      //     : (
      //         (1 -
      //           Number(newOptions[0].price) / Number(newOptions[0].basePrice)) *
      //         100
      //       ).toFixed(2);
      newOptions[0] = {
        ...newOptions[0],
        discount: "",
        price: ""
      };
    }
    newOptionError[0] = {
      ...(newOptionError?.[0] || {}),
      ...(field === "name" ? { price: "" } : {}),
      [field]: value ? "" : errorSingle[fieldName]
    };

    setError({ ...error, optionSingle: newOptionError });
    setOptionSingle(newOptions);
  };

  const handleChangeSideDishSelect = (isChecked, value) => {
    if (isChecked) {
      setSideDishSelect([...sideDishSelect, value]);
    } else {
      const newSideDishSelect = sideDishSelect.filter((item) => item !== value);
      setSideDishSelect(newSideDishSelect);
    }
  };

  const handleResetSetMenu = (isDay) => {
    console.log("run 1 set");
    let newOptions = [...options];
    let newOptionError = error.options;
    if (isDay) {
      newOptions = newOptions.map((item) => ({
        ...item,
        price: "",
        discount: ""
      }));
      setOptions(newOptions);
    } else {
      setHourStart(0);
      setMinuteStart(0);
      setHourEnd(0);
      setMinuteEnd(0);
      newOptions = newOptions.map((item) => ({
        ...item,
        paymentAmount: item.basePrice,
        price: "",
        discount: ""
      }));
      setOptions([...newOptions]);
    }

    // setError({ ...error, options: newOptionError });
  };

  const handleResetSingleMenu = (isDay) => {
    console.log("run 2 single");
    let newOptions = [...optionSingle];
    let newOptionError = error.options;
    if (isDay) {
      newOptions = newOptions.map((item) => ({
        ...item,
        price: "",
        discount: ""
      }));
      setOptionSingle(newOptions);
    } else {
      setHourStart(0);
      setMinuteStart(0);
      setHourEnd(0);
      setMinuteEnd(0);
      newOptions = newOptions.map((item) => ({
        ...item,
        paymentAmount: item.basePrice,
        price: "",
        discount: ""
      }));
      setOptionSingle([...newOptions]);
    }

    // setError({ ...error, options: newOptionError });
  };

  return (
    <div className="dialog">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-11 col-md-8 col-xl-8">
            <div class="mainDiaogBox">
              <div class="row formHead align-items-center justify-content-between">
                <div className="col-8">
                  <h2 className="text-theme m0">
                    메뉴 {dialogueData ? "수정" : "등록"}
                  </h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>

              <div className="row formBody align-items-start">
                {/* checkbox */}
                <div
                  className="d-flex align-items-center"
                  style={{ marginTop: "5px", height: "40px" }}
                >
                  <ExInput
                    type={`radio`}
                    label={`세트`}
                    name={"type"}
                    value={`set`}
                    checked={type === "set"}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  />

                  <ExInput
                    type={`radio`}
                    label={`단품`}
                    name={"type"}
                    value={`single`}
                    checked={type === "single"}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  />
                </div>
                {/* menu */}
                <div className="col-7 col-md-4 inputData">
                  {type === "set" ? (
                    <ExInput
                      type={`text`}
                      label={`메뉴 이름`}
                      name={"name"}
                      value={name}
                      newClass={"inputService"}
                      placeholder="이름을 입력하세요"
                      errorMessage={error?.name}
                      onChange={(e) => {
                        setName(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            name: `Menu name is required`
                          });
                        } else {
                          return setError({
                            ...error,
                            name: ""
                          });
                        }
                      }}
                    />
                  ) : (
                    <>
                      <label>이름</label>
                      <div
                        className="d-flex align-items-center"
                        style={{ marginTop: "4px" }}
                      >
                        <SelectV2
                          id={`hours`}
                          option={
                            type === "sideDish"
                              ? productSideDishList
                              : type === "set"
                              ? productSetList
                              : productSingleList
                          }
                          className={`inputSelectTime`}
                          btnClass={`mt-0`}
                          value={optionSingle?.[0]?.name}
                          errorMessage={error.optionSingle?.[0]?.name}
                          onChange={(value) => {
                            handleChangeSingleOptionField(
                              "name",
                              value,
                              "optionName"
                            );
                          }}
                          placeholder={"옵션 이름을 입력하세요"}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="col-3 col-md-3">
                  <ExInput
                    type={`text`}
                    label={`표시순서`}
                    name={"order"}
                    newClass={"inputService"}
                    value={order}
                    errorMessage={error?.order}
                    onChange={(e) => {
                      if (!e.target.value.match(/^\d+$/) && e.target.value) {
                        return;
                      }
                      setOrder(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          order: errorSingle.order
                        });
                      } else {
                        return setError({
                          ...error,
                          order: ""
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-2">
                  {/* <ExInput
                    type={`text`}
                    label={`낮 시간`}
                    name={"day"}
                    newClass={"inputService"}
                    value={day}
                    errorMessage={error?.day}
                    onChange={(e) => {
                      setDay(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          day: ` Day is required`
                        });
                      } else {
                        return setError({
                          ...error,
                          day: ""
                        });
                      }
                    }}
                  /> */}
                  <div className="d-flex flex-column align-items-center justify-content-center inpuData">
                    <label className="mt-2">낮 시간</label>
                    <input
                      type="checkbox"
                      style={{
                        height: "40px",
                        width: "40px",
                        marginTop: "5px",
                        borderColor: "#cfcfcf"
                      }}
                      checked={day}
                      onChange={(e) => {
                        setDay(e.target.checked);
                        if (type === "set") {
                          handleResetSetMenu(e.target.checked);
                        } else {
                          handleResetSingleMenu(e.target.checked);
                        }
                      }}
                    />
                  </div>
                </div>
                {day && (
                  <div className="row">
                    <div className="col-12 col-md-8 inputData">
                      <label>낮 시간 설정</label>
                      <div
                        className="d-flex align-items-center"
                        style={{ marginTop: "4px" }}
                      >
                        <SelectV2
                          id={`hours`}
                          option={hoursFull}
                          className={`inputSelectTime`}
                          btnClass={`mt-0`}
                          angle={true}
                          value={hourStart}
                          onChange={(value) => setHourStart(value)}
                        />
                        <span className="text-nowrap px-1">시</span>
                        <SelectV2
                          id={`minutes`}
                          option={minutesFull}
                          className={`inputSelectTime`}
                          btnClass={`mt-0`}
                          angle={true}
                          value={minuteStart}
                          onChange={(value) => setMinuteStart(value)}
                        />
                        <span className="px-1">분</span>
                        <span className="px-4">&mdash;</span>
                        <SelectV2
                          id={`hours`}
                          option={hoursFull}
                          className={`inputSelectTime`}
                          btnClass={`mt-0`}
                          angle={true}
                          value={hourEnd}
                          onChange={(value) => setHourEnd(value)}
                        />
                        <span className="text-nowrap px-1">시</span>
                        <SelectV2
                          id={`minutes`}
                          option={minutesFull}
                          className={`inputSelectTime`}
                          btnClass={`mt-0`}
                          angle={true}
                          value={minuteEnd}
                          onChange={(value) => setMinuteEnd(value)}
                        />
                        <span className="px-1">분</span>
                      </div>
                    </div>
                  </div>
                )}
                {/* option single */}
                {(type === "single" || type === "sideDish") && (
                  <div className="row">
                    <div className="col-3">
                      <div className="d-flex inputData">
                        <ExInput
                          type={`text`}
                          label={`가격`}
                          newClass={"inputService"}
                          value={optionSingle[0].basePrice}
                          errorMessage={error.optionSingle?.[0]?.basePrice}
                          disabled
                          onChange={(e) => {
                            if (
                              !e.target.value.match(/^\d+$/) &&
                              e.target.value
                            ) {
                              return;
                            }
                            handleChangeSingleOptionField(
                              "price",
                              e.target.value
                            );
                          }}
                        />
                        <span
                          className="text-nowrap px-1"
                          style={{ marginTop: "40px" }}
                        >
                          원
                        </span>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="d-flex inputData">
                        <ExInput
                          type={`text`}
                          label={`정산금액`}
                          value={optionSingle[0].paymentAmount}
                          errorMessage={error.optionSingle?.[0]?.paymentAmount}
                          onChange={(e) => {
                            if (
                              !e.target.value.match(/^\d+$/) &&
                              e.target.value
                            ) {
                              return;
                            }
                            handleChangeSingleOptionField(
                              "paymentAmount",
                              e.target.value,
                              "settlementAmount"
                            );
                          }}
                        />
                        <span
                          className="text-nowrap px-1"
                          style={{ marginTop: "40px" }}
                        >
                          원
                        </span>
                      </div>
                    </div>
                    {day && (
                      <>
                        <div className="col-3">
                          <div className="d-flex inputData">
                            <ExInput
                              type={`text`}
                              label={`할인가격`}
                              newClass={"inputService"}
                              value={optionSingle[0].price}
                              errorMessage={error.optionSingle?.[0]?.price}
                              onChange={(e) => {
                                if (
                                  !e.target.value.match(/^\d+$/) &&
                                  e.target.value
                                ) {
                                  return;
                                }
                                handleChangeSingleOptionField(
                                  "price",
                                  e.target.value,
                                  "optionBasePrice"
                                );
                              }}
                            />
                            <span
                              className="text-nowrap px-1"
                              style={{ marginTop: "40px" }}
                            >
                              원
                            </span>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="d-flex inputData">
                            <ExInput
                              type={`text`}
                              label={`할인율`}
                              newClass={"inputService"}
                              value={optionSingle[0].discount}
                              disabled
                              onChange={(e) => {
                                if (
                                  !e.target.value.match(/^\d+$/) &&
                                  e.target.value
                                ) {
                                  return;
                                }
                                let newOptions = [...optionSingle];
                                newOptions[0] = {
                                  ...newOptions[0],
                                  discount: e.target.value
                                };
                                setOptionSingle(newOptions);
                              }}
                            />
                            <span
                              className="text-nowrap px-1"
                              style={{ marginTop: "40px" }}
                            >
                              %
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
                {/* description */}
                <div className="col-8 row">
                  <ExInput
                    type={`text`}
                    label={`메뉴 설명`}
                    name={"description"}
                    newClass={"inputService"}
                    placeholder="설명을 입력하세요"
                    value={description}
                    // errorMessage={error?.description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      // if (!e.target.value) {
                      //   return setError({
                      //     ...error,
                      //     descripttion: `Descripttion is required`
                      //   });
                      // } else {
                      //   return setError({
                      //     ...error,
                      //     descripttion: ""
                      //   });
                      // }
                    }}
                  />
                </div>
                {/* options set */}
                {type === "set" &&
                  options.map((option, index) => (
                    <>
                      <div className="row">
                        <div className="col-7 col-md-3 inputData">
                          <label>옵션 이름</label>
                          <div
                            className="d-flex align-items-center"
                            style={{ marginTop: "4px" }}
                          >
                            <SelectV2
                              id={`hours`}
                              option={productSetList}
                              className={`inputSelectTime`}
                              btnClass={`mt-0`}
                              value={option.name}
                              errorMessage={error.options?.[index]?.name}
                              onChange={(value) => {
                                handleChangeOptionField(
                                  "name",
                                  value,
                                  index,
                                  "optionName"
                                );
                              }}
                              placeholder={"옵션 이름을 입력하세요"}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="d-flex inputData">
                            <ExInput
                              type={`text`}
                              label={`가격`}
                              newClass={"inputService"}
                              value={option.basePrice}
                              errorMessage={error.options?.[index]?.basePrice}
                              onChange={(e) => {
                                if (
                                  !e.target.value.match(/^\d+$/) &&
                                  e.target.value
                                ) {
                                  return;
                                }
                                handleChangeOptionField(
                                  "basePrice",
                                  e.target.value,
                                  index
                                );
                              }}
                            />
                            <span
                              className="text-nowrap px-1"
                              style={{
                                marginTop: "40px"
                              }}
                            >
                              원
                            </span>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="d-flex inputData align-items-center">
                            <ExInput
                              type={`text`}
                              label={`정산금액`}
                              newClass={"inputService"}
                              value={option.paymentAmount}
                              errorMessage={
                                error.options?.[index]?.paymentAmount
                              }
                              onChange={(e) => {
                                if (
                                  !e.target.value.match(/^\d+$/) &&
                                  e.target.value
                                ) {
                                  return;
                                }
                                handleChangeOptionField(
                                  "paymentAmount",
                                  e.target.value,
                                  index,
                                  "settlementAmount"
                                );
                              }}
                            />
                            <span
                              className="text-nowrap px-1"
                              style={{ marginTop: "40px" }}
                            >
                              원
                            </span>
                          </div>
                        </div>
                        <div className="col-6 col-md-3 inputData">
                          <label>예약 가능 시간</label>
                          <div
                            className="d-flex align-items-center"
                            style={{ marginTop: "4px" }}
                          >
                            <SelectV2
                              id={`hours`}
                              option={hours}
                              className={`inputSelectTime`}
                              btnClass={`mt-0`}
                              angle={true}
                              value={option.availableReserTimeHour}
                              onChange={(value) => {
                                handleChangeOptionField(
                                  "availableReserTimeHour",
                                  Number(value),
                                  index,
                                  "optionAvailableReserTimeHour"
                                );
                              }}
                            />
                            <span className="text-nowrap px-1">시간</span>
                            <SelectV2
                              id={`minutes`}
                              option={minutes}
                              className={`inputSelectTime`}
                              btnClass={`mt-0`}
                              angle={true}
                              value={option.availableReserTimeMinute}
                              onChange={(value) => {
                                handleChangeOptionField(
                                  "availableReserTimeMinute",
                                  Number(value),
                                  index,
                                  "optionAvailableReserTimeHour"
                                );
                              }}
                            />
                          </div>
                          <p className="text-capitalize errorMessage">
                            {error.options?.[index]?.availableReserTimeHour}
                          </p>
                        </div>
                        {options.length > 1 && (
                          <div
                            className="col-1 h-100 cursor-pointer"
                            style={{ marginTop: "35px" }}
                            onClick={() => handleRemoveOption(index)}
                          >
                            <div
                              className="d-flex align-items-center justify-content-center rounded-2"
                              style={{
                                backgroundColor: "#fff1f1",
                                height: "40px"
                              }}
                            >
                              <svg
                                className="h-6 text-gray-800 w-6 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="#f71845"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                                />
                              </svg>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* row 2 */}
                      {day ? (
                        <div className="row">
                          <div className="col-7 col-md-3">&nbsp;</div>
                          <div className="col-2 col-md-2">
                            <div className="d-flex inputData">
                              <ExInput
                                type={`text`}
                                label={`할인가격`}
                                newClass={"inputService"}
                                value={option.price}
                                errorMessage={error.options?.[index]?.price}
                                onChange={(e) => {
                                  if (
                                    !e.target.value.match(/^\d+$/) &&
                                    e.target.value
                                  ) {
                                    return;
                                  }
                                  handleChangeOptionField(
                                    "price",
                                    e.target.value,
                                    index,
                                    "optionBasePrice"
                                  );
                                }}
                              />
                              <span
                                className="text-nowrap px-1"
                                style={{
                                  marginTop: "40px"
                                }}
                              >
                                원
                              </span>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex inputData align-items-center">
                              <ExInput
                                type={`text`}
                                label={`할인율`}
                                newClass={"inputService"}
                                value={option.discount}
                                disabled
                                onChange={(e) => {
                                  if (
                                    !e.target.value.match(/^\d+$/) &&
                                    e.target.value
                                  ) {
                                    return;
                                  }
                                  let newOptions = [...options];
                                  newOptions[index] = {
                                    ...newOptions[index],
                                    discount: e.target.value
                                  };
                                  setOptions(newOptions);
                                }}
                              />
                              <span
                                className="text-nowrap px-1"
                                style={{ marginTop: "29px" }}
                              >
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))}

                {/* button add option set */}
                {type === "set" && (
                  <div className="col-12 d-flex flex-start">
                    <Button
                      className={`bg-gray text-white`}
                      text={`옵션 추가`}
                      type={`button`}
                      style={{ height: "40px" }}
                      onClick={handleAddOption}
                    />
                  </div>
                )}

                {/* sideDish */}
                {type === "set" && (
                  <div className="flex-row col-md-12 inputData">
                    <label>표시 안주 선택</label>
                    {productSideDishList.map((item) => (
                      <div
                        className="d-flex align-items-center inpuData gap-2"
                        key={item.value}
                      >
                        <input
                          type="checkbox"
                          style={{
                            height: "30px",
                            width: "30px",
                            marginTop: "5px",
                            borderColor: "#cfcfcf"
                          }}
                          checked={sideDishSelect.includes(item.value)}
                          onChange={(e) => {
                            handleChangeSideDishSelect(
                              e.target.checked,
                              item.value
                            );
                          }}
                        />
                        <label className="mt-2">{item.name}</label>
                      </div>
                    ))}
                  </div>
                )}
                {/* image */}
                {/* <div className="flex-row col-md-12">
                  <div className="flex-row inputData justify-content-start text-start file col-md-6">
                    <label htmlFor="name">
                      썸네일 선택{" "}
                      <span
                        style={{
                          fontWeight: "700",
                          color: "#000",
                          marginLeft: "20px"
                        }}
                      >
                        {`※ 권장 이미지 사이즈는 ${325}px x ${325}px 입니다.`}
                      </span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="file"
                        className="position-absolute bottom-0 end-0 mt-auto opacity-0 start-0"
                        id="image"
                        onChange={(e) => {
                          handleImage(e);
                          e.target.value = null;
                        }}
                        style={{
                          height: "40px"
                        }}
                        accept="image/*"
                      />
                      <div
                        className="d-flex border rounded-2"
                        style={{
                          height: "40px"
                        }}
                      >
                        <div
                          className="d-flex align-items-center bg-gray justify-content-center rounded-start-2 text-white"
                          style={{
                            height: "100%",
                            width: "30%"
                          }}
                        >
                          파일 선택
                        </div>
                        <div
                          className="d-flex align-items-center p-1"
                          style={{ width: "70%" }}
                        >
                          <span className="text-truncate">{image?.name}</span>
                        </div>
                      </div>
                    </div>
                    {error && (
                      <p className="text-start errorMessage">
                        {error && error?.image}
                      </p>
                    )}
                    {imagePath ? (
                      <div className="image-start">
                        <img
                          src={imagePath}
                          alt="ServiceImage"
                          draggable="false"
                          className={`${
                            (!imagePath || imagePath == "") && "d-none"
                          }`}
                          width={"100px"}
                          height={"100px"}
                          // data-image={name}
                          data-class={`showImage`}
                        />
                      </div>
                    ) : null}
                  </div>
                </div> */}
                {type === "set" && (
                  <div className="flex-row col-md-12">
                    <div className="flex-row inputData justify-content-start text-start file col-md-6">
                      <label htmlFor="name">
                        메뉴 상세 이미지 선택{" "}
                        <span
                          style={{
                            fontWeight: "700",
                            color: "#000",
                            marginLeft: "20px"
                          }}
                        >
                          {`※ 권장 이미지 사이즈는 ${325}px x ${675}px 입니다.`}
                        </span>
                      </label>
                      <div className="position-relative">
                        <input
                          type="file"
                          className="position-absolute bottom-0 end-0 mt-auto opacity-0 start-0"
                          id="image"
                          onChange={(e) => {
                            handleImageDetail(e);
                            e.target.value = null;
                          }}
                          style={{
                            height: "40px"
                          }}
                          accept="image/*"
                        />
                        <div
                          className="d-flex border rounded-2"
                          style={{
                            height: "40px"
                          }}
                        >
                          <div
                            className="d-flex align-items-center bg-gray justify-content-center rounded-start-2 text-white"
                            style={{
                              height: "100%",
                              width: "30%"
                            }}
                          >
                            파일 선택
                          </div>
                          <div
                            className="d-flex align-items-center p-1"
                            style={{ width: "70%" }}
                          >
                            <span className="text-truncate">
                              {imageDetail?.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      {error && (
                        <p className="text-start errorMessage">
                          {error && error?.imageDetail}
                        </p>
                      )}
                      {imageDetailPath ? (
                        <div className="image-start">
                          <img
                            src={imageDetailPath}
                            alt="ServiceImage"
                            draggable="false"
                            className={`${
                              (!imageDetailPath || imageDetailPath == "") &&
                              "d-none"
                            }`}
                            width={"100px"}
                            height={"100px"}
                            // data-image={name}
                            data-class={`showImage`}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
              <div className="row formFooter mt-3">
                <div className="col-12 text-end m0">
                  <Button
                    className={`bg-gray text-light`}
                    text={`취소`}
                    type={`button`}
                    onClick={() => dispatch(closeDialog())}
                  />
                  <Button
                    type={`submit`}
                    className={`text-white m10-left`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={dialogueData ? "수정" : "등록"}
                    disabled={isLoading}
                    onClick={() => handleSubmit()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServiceDialogue;
