import React, { useEffect, useRef, useState } from "react";
import Button from "../../extras/Button";
import { ExInput, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import { cancelBooking } from "../../../redux/slice/bookingSlice";

const CancelBookingDialog = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const loadingRef = useRef(false);

  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [mongoId, setMongoId] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (dialogueData?.id) {
      setMongoId(dialogueData?.id);
    }
  }, [dialogueData?.id]);

  const handleSubmit = async (e) => {
    loadingRef.current = true;
    e.preventDefault();
    if (!reason) {
      setError("이유가 필요합니다");
    } else {
      if (dialogueData) {
        const payload = {
          reason: reason,
          reservationId: mongoId
        };
        const res = await dispatch(cancelBooking(payload));
        console.log("res", res);
        if (res?.payload?.data?.data) {
          props.refetch && props.refetch();
          dispatch(closeDialog());
          loadingRef.current = false;
        }
        loadingRef.current = false;
      }
    }
  };

  return (
    <div className="dialog">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-3 col-md-4 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">매장 취소</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <form id="expertForm">
                <div className="row align-items-start formBody">
                  <div className="col-12">
                    <ExInput
                      type={`text`}
                      label={"취소자"}
                      value={"매장 요청"}
                      disabled
                    />
                  </div>
                  <div className="col-12">
                    <Textarea
                      value={reason}
                      row={5}
                      onChange={(e) => {
                        setReason(e.target.value);
                        if (!e.target.value) {
                          return setError("이유가 필요합니다");
                        } else {
                          setError("");
                        }
                      }}
                      label={`사유`}
                    />
                    {error && (
                      <p className="errorMessage text-start">
                        {error && error}
                      </p>
                    )}
                  </div>
                </div>
                <div className="row  formFooter">
                  <div className="col-12 text-end m0">
                    <Button
                      type={`button`}
                      className={` text-white m10-left`}
                      style={{ backgroundColor: "#1ebc1e" }}
                      text={`취소하기`}
                      disabled={loadingRef.current}
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CancelBookingDialog;
