import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import { confirm, warning } from "../../../util/Alert";
import Title from "../../extras/Title";
import Button from "../../extras/Button";
import Searching, { SearchingWithSelect } from "../../extras/Searching";
import Table from "../../extras/Table";
import Pagination from "../../extras/Pagination";
import * as XLSX from "xlsx";
import {
  activesalon,
  getAllSalons,
  salonDelete
} from "../../../redux/slice/salonSlice";
import { apiInstance } from "../../api/axiosApi";
import { rootRole } from "../../../util/constants";

const typeSearch = [
  {
    value: "StoreName",
    text: "매장 이름"
  },
  {
    value: "PhoneNumber",
    text: "대표 번호"
  },
  {
    value: "StoreCode",
    text: "매장 코드"
  }
];

export const Salon = () => {
  const [data, setData] = useState([]);
  const { admin } = useSelector((state) => state.auth);

  const { salon = [], count = 0 } = useSelector((state) => state.salon);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState({
    text: "",
    type: ""
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isRoot = useMemo(() => {
    return admin.permissionCategory?.category === rootRole;
  }, [admin]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch({
        ...filteredData,
        text: filteredData
      });
    } else {
      setData(filteredData);
    }
  };

  const handleFilterDataType = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch({
        ...filteredData,
        type: filteredData
      });
    } else {
      setData(filteredData);
    }
  };

  const handleSearch = (stringSearch, type) => {
    if (stringSearch !== search.text || type !== search.type) {
      setPage(0);
    }
    setSearch({
      text: stringSearch,
      type: type
    });
  };

  const fetchData = useCallback(() => {
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
      searchText: search.text,
      searchFilter: search.type
    };
    dispatch(getAllSalons(payload));
  }, [page, dispatch, rowsPerPage, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  console.log("data", data);

  useEffect(() => {
    setData(salon);
  }, [salon]);

  const handleOpenMenu = (row) => {
    navigate("/admin/store/menu", {
      state: {
        row
      }
    });
  };

  const salonTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{count - ((page + 1 - 1) * rowsPerPage + index)}</span>
      )
    },
    {
      Header: "매장 이름",
      Cell: ({ row }) => (
        <span
          className="text-capitalize text-decoration-underline cursor cursor-pointer fw-bold"
          onClick={() => handleAddSalon(row)}
        >
          {row?.storeName}
        </span>
      )
    },
    {
      Header: "대표 번호",
      Cell: ({ row }) => (
        <span>{row?.storePhoneNumber ? row?.storePhoneNumber : "-"}</span>
      )
    },
    {
      Header: "매장 코드",
      Cell: ({ row }) => <span className="">{row?.storeCode}</span>
    },
    {
      Header: "활성화",
      body: "isActive",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isActive}
          onClick={async () => {
            await dispatch(
              activesalon({
                storeId: row?.storeId,
                formData: {
                  isActive: !row?.isActive
                }
              })
            );
          }}
        />
      )
    },
    {
      Header: "예약 현황",
      body: "name",
      Cell: ({ row }) => (
        <button
          className="text-decoration-underline py-1"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenBookings(row)}
        >
          예약현황 보기
        </button>
      )
    },
    {
      Header: "메뉴",
      Cell: ({ row }) => (
        <span>
          <button
            className="text-decoration-underline py-1"
            // style={{ borderRadius: "8px", backgroundColor: "#E0F0FF" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => handleOpenMenu(row)}
          >
            메뉴 보기
          </button>
        </span>
      )
    },
    {
      Header: "영업 시간",
      Cell: ({ row }) => (
        <button
          className="text-decoration-underline py-1"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleScheduleInfo(row)}
        >
          영업 시간 보기
        </button>
      )
    },
    {
      Header: "정산",
      Cell: ({ row }) => (
        <span className="d-flex justify-content-center">
          <button
            className="text-decoration-underline py-1"
            // style={{ backgroundColor: "#CFF3FF", borderRadius: "8px" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => {
              handleEarning(row);
            }}
          >
            정산
          </button>
        </span>
      )
    },
    {
      Header: "이의 관리",
      Cell: ({ row }) => (
        <span className="d-flex justify-content-center">
          <button
            className="text-decoration-underline py-1"
            // style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => handleOpenComplain(row)}
          >
            이의 관리
          </button>
        </span>
      )
    },
    {
      Header: "수정",
      Cell: ({ row }) => (
        <span className="d-flex justify-content-center">
          <button
            className="text-decoration-underline py-1"
            // style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => handleAddSalon(row)}
          >
            수정
          </button>
        </span>
      )
    },
    {
      Header: "삭제",
      Cell: ({ row }) => (
        <span className="d-flex justify-content-center">
          {isRoot && (
            <button
              className="text-decoration-underline py-1"
              // style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
              style={{ backgroundColor: "#fff" }}
              onClick={() => handleDelete(row?.storeId)}
            >
              삭제
            </button>
          )}
        </span>
      )
    }
  ];

  const handleAddSalon = (row) => {
    navigate("/admin/store/addStore", {
      state: {
        row
      }
    });
  };

  const handleEarning = (row) => {
    navigate("/admin/store/settlement", {
      state: {
        row
      }
    });
  };

  const handleDelete = (id) => {
    try {
      const data = confirm({
        title: "삭제 하시겠습니까?",
        text: "삭제 버튼 클릭 시 되돌릴 수 없습니다.",
        confirm: "삭제",
        cancle: "취소"
      });
      data
        .then((confirm) => {
          const yes = confirm.isConfirmed;
          console.log("yes", yes);
          if (yes) {
            dispatch(salonDelete(id));
            setTimeout(() => {
              fetchData();
            }, 1000);
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  const handleScheduleInfo = (row) => {
    navigate("/admin/store/schedule", { state: { row } });
  };
  const handleOpenBookings = (row) => {
    navigate("/admin/store/bookings", { state: { data: row } });
  };

  const handleOpenComplain = (row) => {
    navigate("/admin/store/complains", { state: { data: row } });
  };

  const handleDownloadExcel = async () => {
    const res = await apiInstance.get(
      `admin/stores?searchText=${search?.text}&searchFilter=${search?.type}`
    );
    console.log("res", res);
    const flattenedData = (res?.data || []).map((item, index) => ({
      순번: index + 1,
      "매장 이름": item?.storeName,
      "대표 번호": item?.storePhoneNumber,
      "매장 코드": item?.storeCode
    }));

    const headers = ["순번", "매장 이름", "대표 번호", "매장 코드"];
    const ws = XLSX.utils.json_to_sheet(flattenedData);

    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let col = 0; col < headers.length; col++) {
      const cellAddress = { r: range.s.r, c: col };
      const cell = { v: headers[col], t: "s" };
      ws[XLSX.utils.encode_cell(cellAddress)] = cell;
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "매장 관리");
    XLSX.writeFile(wb, "매장 관리.xlsx");
  };

  return (
    <div className="userTable">
      <Title name="매장 관리" />

      <div className="betBox mb-3">
        <div className="col-lg-7 col-md-8 inputData">
          <label>검색</label>
          <SearchingWithSelect
            type={`server`}
            data={data}
            typeSearch={typeSearch}
            setData={setData}
            column={salonTable}
            serverSearchingType={handleFilterData}
            serverSearchingValue={handleFilterDataType}
            handleSearch={handleSearch}
            selectPlaceholder="선택"
            button
          />
        </div>
        <Button
          className={`bg-button-excel p-10 text-black font-bold m10-bottom ms-auto mt-auto`}
          text={`다운로드`}
          style={{ height: "40px" }}
          // bIcon={`fa-solid fa-user-plus`}
          onClick={() => handleDownloadExcel()}
        />
        {isRoot && (
          <Button
            className={`bg-button p-10 text-white m10-bottom mt-auto`}
            style={{ marginLeft: "20px", height: "40px" }}
            text={`매장 추가`}
            // bIcon={`fa-solid fa-user-plus`}
            onClick={() => handleAddSalon()}
          />
        )}
      </div>
      <div>
        <Table
          data={data}
          mapData={salonTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"server"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={count}
        />
      </div>
    </div>
  );
};
