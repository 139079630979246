import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../component/api/axiosApi";
import { Success } from "../../component/api/toastServices";
import { genQueryString } from "../../util/fuction";

const initialState = {
  review: [],
  isLoading: false,
  isSkeleton: false
};

export const getAllReview = createAsyncThunk(
  "admin/review/getAll",
  async (payload) => {
    return apiInstance.get(genQueryString("admin/rating", payload));
  }
);

export const deleteReview = createAsyncThunk(
  "admin/review/delete",
  async (id) => {
    return apiInstance.delete(`admin/review/delete?reviewId=${id}`);
  }
);
const reviewSlice = createSlice({
  name: "reviewSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllReview.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllReview.fulfilled, (state, action) => {
      state.review = action.payload.data;
      state.total = action.payload.total;
      state.isSkeleton = false;
    });

    builder.addCase(getAllReview.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(deleteReview.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(deleteReview.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.review = state.review.filter(
          (review) => review._id !== action.meta.arg
        );
        Success("리뷰 삭제되었습니다.");
      }
      state.isLoading = false;
    });
    builder.addCase(deleteReview.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});
export default reviewSlice.reducer;
