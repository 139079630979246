// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "../../extras/Button";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../extras/Title";
import {
  getSetting,
  handleSetting,
  maintenanceMode,
  updateSetting
} from "../../../redux/slice/settingSlice";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { ExInput, Textarea } from "../../extras/Input";
import { isNumber } from "../../../util/fuction";

const WithdrawalSetting = (props) => {
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state.setting);

  const [points, setPoints] = useState();

  const [error, setError] = useState({
    points: ""
  });

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  useEffect(() => {
    if (setting) {
      setPoints(setting.minimumWithdrawalAmount);
    }
  }, [setting]);

  const onsubmit = async (e) => {
    e.preventDefault();

    if (!points) {
      let error = {};
      if (!points) error.points = "최소 출금 포인트가 필요합니다.";

      return setError({ ...error });
    } else {
      const body = {
        minimumWithdrawalAmount: Number(points)
      };
      const res = await dispatch(updateSetting(body));
      console.log("res", res);
      if (res?.payload?.data?.data) {
        dispatch(getSetting());
      }
    }
  };

  return (
    <div className="mainSetting">
      <Title name="출금 관리" />
      <div className="settingBox">
        <div className="row">
          <div className="col-12">
            <ExInput
              type={`text`}
              value={points}
              label={`최소 출금 포인트`}
              errorMessage={error.points}
              onChange={(e) => {
                if (
                  (isNumber(e.target.value) && e.target.value) ||
                  !e.target.value
                ) {
                  setPoints(e.target.value);
                }
                if (!e.target.value) {
                  return setError({
                    ...error,
                    points: `최소 출금 포인트가 필요합니다.`
                  });
                } else {
                  return setError({
                    ...error,
                    points: ""
                  });
                }
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div className="formFooter">
            <Button
              type={`submit`}
              className={`text-light m10-left fw-bold`}
              text={`저장`}
              style={{ backgroundColor: "#1ebc1e" }}
              onClick={onsubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithdrawalSetting;
