import React, { useEffect, useState } from "react";
import { closeDialog, openDialog } from "../../../redux/slice/dialogueSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extras/Button";
import Input, { ExInput, Textarea } from "../../extras/Input";

import {
  addSalon,
  getSalonDetail,
  updateSalon
} from "../../../redux/slice/salonSlice";
import ReactDropzone from "react-dropzone";
import Title from "../../extras/Title";
import { useLocation, useNavigate } from "react-router-dom";
import GenderGroup from "../../extras/GenderGroup";
import AddressModal from "./AddressModal";
import { uploadFile } from "../../../redux/api";
import { SuccessStatusCode } from "../../../util/constants";

const errorMessgae = {
  name: "매장 이름을 입력하세요",
  mobile: "매장 전화번호를 입력하세요",
  numberTables: "매장 테이블 수를 입력하세요",
  address: "매장 주소를 입력하세요",
  youtube: "",
  naver: "",
  instagram: "",
  images: "사진을 등록해 주세요",
  mainImage: "사진을 등록해 주세요"
};
const AddSalon = () => {
  const { state } = useLocation();
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const { salonDetail } = useSelector((state) => state.salon);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState();
  const [numberTables, setNumberTables] = useState();
  const [genderReser, setGenderReser] = useState("male");
  const [genderBatender, setGenderBatender] = useState("male");
  const [address, setAddress] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [youtube, setYoutube] = useState({
    name: "YOUTUBE",
    address: "",
    id: null
  });
  const [naver, setNaver] = useState({
    name: "BLOG",
    address: "",
    id: null
  });
  const [instagram, setInstagram] = useState({
    name: "INSTAGRAM",
    address: "",
    id: null
  });
  const [mainImage, setMainImage] = useState([]);
  const [images, setImages] = useState([]);
  const [mainImagePreview, setMainImagePreview] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [mobile, setMobile] = useState();
  const [error, setError] = useState({
    name: "",
    email: "",
    address: "",
    landMark: "",
    city: "",
    state: "",
    country: "",
    images: "",
    mainImage: "",
    mobile: "",
    platformFee: "",
    latitude: "",
    longitude: "",
    password: "",
    about: "",
    numberTables: "",
    youtube: "",
    naver: "",
    instagram: ""
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (state?.row?.storeId) {
      dispatch(getSalonDetail(state?.row?.storeId));
    }
  }, [dispatch, state]);

  useEffect(() => {
    if (state?.row?.storeId === salonDetail?.storeId) {
      setName(state?.row?.storeName);
      setNumberTables(state?.row?.numberOfTables);
      setAddress(state?.row?.address);
      setMobile(state?.row?.storePhoneNumber);
      setMainImage(state?.row?.thumbnailUrl ? [state?.row?.thumbnailUrl] : []);
      setMainImagePreview(
        state?.row?.thumbnailUrl ? [state?.row?.thumbnailUrl] : []
      );
      setImages(salonDetail?.photos || []);
      setImagesPreview(salonDetail?.photos || []);
      setGenderReser(state?.row?.reservationGender === 1 ? "male" : "female");
      setGenderBatender(state?.row?.workGender === 1 ? "male" : "female");
      const snsAddresses = state?.row?.snsAddresses || [];
      setYoutube(
        snsAddresses.find(
          (item) => item.name === "youtube" || item.name === "YOUTUBE"
        ) || {
          name: "YOUTUBE",
          address: "",
          id: null
        }
      );
      setInstagram(
        snsAddresses.find(
          (item) => item.name === "instagram" || item.name === "INSTAGRAM"
        ) || {
          name: "INSTAGRAM",
          address: "",
          id: null
        }
      );
      setNaver(
        snsAddresses.find(
          (item) => item.name === "naver" || item.name === "BLOG"
        ) || {
          name: "BLOG",
          address: "",
          id: null
        }
      );
    }
  }, [state, salonDetail]);

  const onPreviewDrop = async (files) => {
    const first10Files =
      images.length === 10
        ? images
        : files.length + images.length > 10
        ? [...images, ...[...files].slice(0, 10 - images.length)]
        : [...images, ...files];
    setError({ ...error, images: "" });
    setImages(first10Files);
    const newFiles = [...first10Files];
    newFiles.map((file) => {
      if (file.fileUrl) {
        return file;
      }
      return Object.assign(file, { preview: URL.createObjectURL(file) });
    });
    setImagesPreview(newFiles);
  };

  const onPreviewMainDrop = (files) => {
    setError({ ...error, mainImage: "" });
    setMainImage(files);
    const newFiles = [...files];
    newFiles.map((file) => {
      if (file.fileUrl) {
        return file;
      }
      return Object.assign(file, { preview: URL.createObjectURL(file) });
    });
    setMainImagePreview(newFiles);
  };

  const removeImage = (file, index) => {
    if (file?.preview) {
      const image = images.filter((ele) => {
        return ele?.preview !== file?.preview;
      });
      setImages(image);
    } else if (file?.fileUrl) {
      const image = images.filter((ele) => {
        return ele?.fileUrl !== file?.fileUrl;
      });
      setImages(image);
    } else {
      const image = images.filter((ele) => {
        return ele !== file;
      });
      setImages(image);
    }
    const imagePreview = [...imagesPreview];
    imagePreview.splice(index, 1);
    setImagesPreview(imagePreview);
  };

  const removeMainImage = () => {
    setMainImage([]);
    setMainImagePreview([]);
  };

  const handlePhotosEdit = () => {
    const photosDetail = salonDetail.photos || [];
    const photos = [...images];
    const updateImages = photos
      .filter((item) => !!item.id)
      .map((item) => ({ ...item, action: "UPDATE" }));

    const deleteImages = photosDetail
      .filter((image) => !updateImages.find((item) => item.id === image.id))
      .map((item) => ({ ...item, action: "DELETE" }));

    const newIamges = photos.filter((item) => !!!item.id);

    return { prevImages: [...updateImages, ...deleteImages], newIamges };
  };

  const handleSubmit = async (e) => {
    if (
      !name ||
      !mobile ||
      !address ||
      !images ||
      // !youtube.address ||
      // !naver.address ||
      // !instagram.address ||
      !numberTables ||
      images?.length === 0 ||
      images?.length > 10 ||
      mainImage?.length === 0 ||
      mainImage?.length > 1
    ) {
      let error = {};
      if (!name) error.name = errorMessgae.name;
      if (!mobile) error.mobile = errorMessgae.mobile;
      if (!numberTables) error.numberTables = errorMessgae.numberTables;
      if (!address) error.address = errorMessgae.address;
      // if (!youtube.address) error.youtube = "Youtube is required";
      // if (!naver.address) error.naver = "Naver is required";
      // if (!instagram.address) error.instagram = "Instagram is required";
      if (images?.length === 0) error.images = errorMessgae.images;
      if (images?.length > 10) error.images = "Select max 10 images";
      if (mainImage?.length === 0) error.mainImage = errorMessgae.mainImage;
      if (mainImage?.length > 10) error.mainImage = "Select max 1 image";

      return setError({ ...error });
    } else {
      setIsLoading(true);
      setError({});

      // upload thumbnail file
      let thumbnail = "";
      if (typeof mainImage[0] === "string") {
        thumbnail = mainImage[0];
      } else {
        const formDataMainImage = new FormData();
        formDataMainImage.append("file", mainImage[0]);
        const mainImageRes = await uploadFile(formDataMainImage);
        thumbnail = mainImageRes.data.filepath;
      }

      // upload photos file
      let photos = [];
      if (!state?.row?.storeId) {
        const uploadPromises = images.map((image) => {
          const formData = new FormData();
          formData.append("file", image);
          return uploadFile(formData);
        });
        const imagesRes = await Promise.all(uploadPromises);
        photos = imagesRes.map((imageRes) => imageRes.data.filepath);
      } else {
        const { prevImages, newIamges } = handlePhotosEdit();
        const uploadPromises = newIamges.map((image) => {
          const formData = new FormData();
          formData.append("file", image);
          return uploadFile(formData);
        });
        const imagesRes = await Promise.all(uploadPromises);
        photos = [
          ...prevImages,
          ...imagesRes.map((imageRes) => ({
            fileUrl: imageRes.data.filepath
          }))
        ];
      }

      let snsAddresses = [];
      if (!state?.row?.storeId) {
        snsAddresses = [
          {
            name: "YOUTUBE",
            address: youtube.address
          },
          {
            name: "BLOG",
            address: naver.address
          },
          {
            name: "INSTAGRAM",
            address: instagram.address
          }
        ];
      } else {
        snsAddresses = [
          {
            name: "YOUTUBE",
            address: youtube.address
            // action: "UPDATE",
            // id: youtube.id
          },
          {
            name: "BLOG",
            address: naver.address
            // action: "UPDATE",
            // id: naver.id
          },
          {
            name: "INSTAGRAM",
            address: instagram.address
            // action: "UPDATE",
            // id: instagram.id
          }
        ];
      }

      const body = {
        name: name,
        phoneNumber: mobile,
        address: address,
        latitude: latitude,
        longitude: longitude,
        thumbnail: thumbnail,
        photos: photos,
        tableNumber: Number(numberTables),
        reservationGender: genderReser === "male" ? 1 : 2,
        workGender: genderBatender === "male" ? 1 : 2,
        snsAddresses: snsAddresses
      };

      try {
        if (state?.row?.storeId) {
          let payload = { storeId: state?.row?.storeId, formData: body };
          const res = await dispatch(updateSalon(payload));
          console.log("res update", res);
          if (res?.payload?.statusCode === SuccessStatusCode) {
            dispatch(getSalonDetail(state?.row?.storeId));
            navigate("/admin/allStore");
          }
        } else {
          const res = await dispatch(addSalon(body));
          console.log("res add", res);
          if (res?.payload?.statusCode === SuccessStatusCode) {
            navigate("/admin/allStore");
          }
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleOpenAddressModal = (id) => {
    dispatch(openDialog({ type: "addressModal", data: address }));
  };

  const handleSelectAddress = (address_name, x, y) => {
    setAddress(address_name);
    setLatitude(x);
    setLongitude(y);
  };

  return (
    <div className="p-3">
      <Title name={state?.row ? `매장 수정` : "매장 추가"} />
      <div className="card">
        {dialogue && dialogueType === "addressModal" && (
          <div className="userTable">
            <AddressModal handleSelectAddress={handleSelectAddress} />
          </div>
        )}
        <div className="card-body">
          <div class="">
            <div className="row align-items-start formBody">
              <div className="col-12 col-md-6 col-lg-4">
                <ExInput
                  type={`text`}
                  id={`name`}
                  name={`name`}
                  value={name}
                  label={`매장 이름`}
                  placeholder={`매장 이름을 입력하세요.`}
                  errorMessage={error.name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        name: errorMessgae.name
                      });
                    } else {
                      return setError({
                        ...error,
                        name: ""
                      });
                    }
                  }}
                />
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <ExInput
                  type={`text`}
                  value={mobile}
                  id={`mono`}
                  name={`mobile`}
                  label={`대표 전화번호`}
                  minLength={6}
                  maxLength={13}
                  placeholder={`전화번호를 입력하세요.`}
                  errorMessage={error.mobile}
                  onChange={(e) => {
                    setMobile(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        mobile: errorMessgae.mobile
                      });
                    }
                    // else if (
                    //   e.target.value.length < 6 ||
                    //   e.target.value.length > 13
                    // ) {
                    //   return setError({
                    //     ...error,
                    //     mobile: "Mobile number must be 6 to 13 digits"
                    //   });
                    // }
                    else {
                      return setError({
                        ...error,
                        mobile: ""
                      });
                    }
                  }}
                />
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <ExInput
                  type={`text`}
                  id={`numberTables`}
                  name={`numberTables`}
                  value={numberTables}
                  label={`테이블 수`}
                  placeholder={`테이블 수를 입력하세요`}
                  errorMessage={error.numberTables}
                  onChange={(e) => {
                    if (!e.target.value.match(/^\d+$/) && e.target.value) {
                      return;
                    }
                    setNumberTables(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        numberTables: errorMessgae.numberTables
                      });
                    } else {
                      return setError({
                        ...error,
                        numberTables: ""
                      });
                    }
                  }}
                />
              </div>

              <div className="col-12 col-md-6 col-lg-6 d-flex align-items-end">
                <ExInput
                  type={`text`}
                  id={`address`}
                  name={`Address`}
                  value={address}
                  label={`매장 주소`}
                  disabled={true}
                  errorMessage={error.address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        address: errorMessgae.address
                      });
                    } else {
                      return setError({
                        ...error,
                        address: ""
                      });
                    }
                  }}
                />
                <Button
                  className={`bg-button-excel text-black font-bold`}
                  style={{
                    marginBottom: error.address ? "30px" : "5px",
                    padding: "8px 12px",
                    marginLeft: "20px",
                    minWidth: "70px"
                  }}
                  text={`검색`}
                  onClick={() => handleOpenAddressModal()}
                />
              </div>

              <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                <div style={{ marginRight: "40px" }}>
                  <GenderGroup
                    id={`genderReser`}
                    label={`예약 가능 성별`}
                    gender={genderReser}
                    onChange={(value) => setGenderReser(value)}
                  />
                </div>
                <GenderGroup
                  id={`genderBatender`}
                  label={`근무 가능 성별`}
                  gender={genderBatender}
                  onChange={(value) => setGenderBatender(value)}
                />
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <ExInput
                  type={`text`}
                  id={`youtube`}
                  name={`youtube`}
                  value={youtube.address}
                  label={`유튜브`}
                  placeholder={`https://youtube.com`}
                  errorMessage={error.youtube}
                  onChange={(e) => {
                    setYoutube({ ...youtube, address: e.target.value });
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        youtube: `Youtube is required`
                      });
                    } else {
                      return setError({
                        ...error,
                        youtube: ""
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <ExInput
                  type={`text`}
                  id={`instagram`}
                  name={`instagram`}
                  value={instagram.address}
                  label={`인스타그램`}
                  placeholder={`https://www.instagram.com`}
                  errorMessage={error.instagram}
                  onChange={(e) => {
                    setInstagram({ ...instagram, address: e.target.value });
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        instagram: `instagram is required`
                      });
                    } else {
                      return setError({
                        ...error,
                        instagram: ""
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <ExInput
                  type={`text`}
                  id={`naver`}
                  name={`naver`}
                  value={naver.address}
                  label={`네이버 블로그`}
                  placeholder={`https://blog.naver.com`}
                  errorMessage={error.naver}
                  onChange={(e) => {
                    setNaver({ ...naver, address: e.target.value });
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        naver: `Naver is required`
                      });
                    } else {
                      return setError({
                        ...error,
                        naver: ""
                      });
                    }
                  }}
                />
              </div>

              {/* {state?.row && (
                <div className="col-12 d-flex">
                  <ExInput
                    label={`Main Image`}
                    id={`mainImage`}
                    type={`file`}
                    onChange={(e) => handleImage(e)}
                    errorMessage={error.mainImage && error.mainImage}
                    accept={"image/*"}
                  />
                  <img
                    src={imagePath}
                    alt=""
                    draggable="false"
                    className={`${
                      (!imagePath || imagePath === "") && "d-none"
                    } ms-4`}
                    data-class={`showImage`}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "10px"
                    }}
                  />
                </div>
              )} */}
              <div className="col-12">
                <div class="inputData text  flex-row justify-content-start text-start">
                  <label class="false ">
                    대표 사진 선택 ({mainImage?.length || 0}/1)
                  </label>

                  <ReactDropzone
                    onDrop={(acceptedFiles) => onPreviewMainDrop(acceptedFiles)}
                    accept="image/*"
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div
                            style={{
                              height: 130,
                              width: 130,
                              border: "2px dashed gray",
                              textAlign: "center",
                              marginTop: "10px"
                            }}
                          >
                            <i
                              className="fas fa-plus"
                              style={{ paddingTop: 30, fontSize: 70 }}
                            ></i>
                          </div>
                        </div>
                      </section>
                    )}
                  </ReactDropzone>
                  <p className="errorMessage text-capitalize">
                    {error.mainImage}
                  </p>
                </div>
              </div>
              <div className="col-12 d-flex">
                {mainImagePreview?.length > 0 && (
                  <>
                    {mainImagePreview.map((file, index) => {
                      return (
                        <div key={index}>
                          <img
                            height="60px"
                            width="60px"
                            alt="representative"
                            src={file?.preview ? file?.preview : file}
                            style={{
                              height: "100px",
                              width: "100px",
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              float: "left",
                              marginRight: 15
                            }}
                          />
                          <div
                            className="img-container"
                            style={{
                              display: "inline",
                              position: "relative",
                              float: "left"
                            }}
                          >
                            <i
                              className="fas fa-times-circle text-danger"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "4px",
                                cursor: "pointer"
                              }}
                              onClick={() => removeMainImage()}
                            ></i>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
              <div className="col-12">
                <div class="inputData text  flex-row justify-content-start text-start">
                  <label class="false ">
                    사진 선택 ({images?.length || 0}/10)
                  </label>

                  <ReactDropzone
                    onDrop={(acceptedFiles) => onPreviewDrop(acceptedFiles)}
                    accept="image/*"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div
                            style={{
                              height: 130,
                              width: 130,
                              border: "2px dashed gray",
                              textAlign: "center",
                              marginTop: "10px"
                            }}
                          >
                            <i
                              className="fas fa-plus"
                              style={{ paddingTop: 30, fontSize: 70 }}
                            ></i>
                          </div>
                        </div>
                      </section>
                    )}
                  </ReactDropzone>
                  <p className="errorMessage text-capitalize">{error.images}</p>
                </div>
              </div>
              <div className="col-12 d-flex flex-wrap">
                {imagesPreview?.length > 0 && (
                  <>
                    {imagesPreview.map((file, index) => {
                      return (
                        <div key={index}>
                          <img
                            height="60px"
                            width="60px"
                            alt="representative"
                            src={
                              file?.preview
                                ? file?.preview
                                : file?.fileUrl
                                ? file?.fileUrl
                                : file
                            }
                            style={{
                              height: "100px",
                              width: "100px",
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              float: "left",
                              marginRight: 15
                            }}
                          />
                          <div
                            className="img-container"
                            style={{
                              display: "inline",
                              position: "relative",
                              float: "left"
                            }}
                          >
                            <i
                              className="fas fa-times-circle text-danger"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "4px",
                                cursor: "pointer"
                              }}
                              onClick={() => removeImage(file, index)}
                            ></i>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>

            <div className="row  formFooter">
              <div className="col-12 text-end m0">
                <Button
                  className={`bg-gray text-light`}
                  text={`취소`}
                  type={`button`}
                  onClick={() => navigate(-1)}
                />
                <Button
                  type={`submit`}
                  className={` text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`등록`}
                  disabled={isLoading}
                  onClick={(e) => handleSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSalon;
