import React, { useEffect, useMemo, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "../../extras/Button";
import Title from "../../extras/Title";
import { ExInput } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import AddCategory from "./AddCategory";
import AddTag from "./AddTag";
import RecommendTag from "./RecommendTag";
import { useCallback } from "react";
import { getAllHashtag } from "../../../redux/slice/hashTagSlice";
import { getSetting, updateSetting } from "../../../redux/slice/settingSlice";
import { bulkUpdateHashTagCategory } from "../../../redux/slice/hashTagSlice";
import { isNumber } from "../../../util/fuction";
import { SuccessStatusCode } from "../../../util/constants";

function getCategoriesWithRecommendedHashTags(hashTags) {
  return hashTags.filter((category) =>
    category.hashTags.some((hashTag) => hashTag.isRecommended)
  );
}

const handleFilterRecommended = (hashTags) => {
  const categoryHasRecommened = getCategoriesWithRecommendedHashTags(hashTags);
  return categoryHasRecommened.map((category) => ({
    ...category,
    hashTags: category.hashTags.filter((hashTag) => hashTag.isRecommended)
  }));
};

export default function HashTag() {
  const [limit, setLimit] = useState();
  const dispatch = useDispatch();
  const { hashTag } = useSelector((state) => state.hashTag);
  const { setting } = useSelector((state) => state.setting);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const [data, setData] = useState([]);
  const [error, setError] = useState({
    limit: ""
  });

  const fetchData = useCallback(() => {
    dispatch(getAllHashtag());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  useEffect(() => {
    if (setting) {
      setLimit(setting.maximumTags);
    }
  }, [setting]);

  useEffect(() => {
    setData(hashTag);
  }, [hashTag]);

  const recommnedData = useMemo(() => {
    return handleFilterRecommended(hashTag);
  }, [hashTag]);

  const handleOpenAddCategory = () => {
    dispatch(openDialog({ type: "addHashTagCategory", data: data }));
  };

  const handleOpenAddHashTag = (data) => {
    dispatch(openDialog({ type: "addHashTag", data: data }));
  };

  const handleOpenAddRecommend = () => {
    dispatch(openDialog({ type: "addRecommend", data: data }));
  };

  const onsubmit = async (e) => {
    e.preventDefault();

    if (!limit) {
      const error = {};
      if (!limit) error.limit = "해시태그의 최대 개수가 필요합니다.";

      return setError({ ...error });
    } else {
      const body = {
        maximumTags: Number(limit)
      };
      const res = await dispatch(updateSetting(body));
      if (res?.data?.data) {
        dispatch(getSetting());
      }
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setData(items);
    console.log(items);
    const updateCategories = items.map((item, idx) => ({
      id: item.id,
      order: idx + 1
    }));
    const res = await dispatch(bulkUpdateHashTagCategory({ updateCategories }));

    if (res.payload?.statusCode === SuccessStatusCode) {
      fetchData();
    }
  };

  return (
    <div className="p-3">
      {dialogue && dialogueType === "addHashTagCategory" && (
        <AddCategory refetch={fetchData} />
      )}
      {dialogue && dialogueType === "addHashTag" && (
        <AddTag refetch={fetchData} />
      )}
      {dialogue && dialogueType === "addRecommend" && (
        <RecommendTag refetch={fetchData} />
      )}
      <Title name={"바텐더 해시태그 설정"} />
      <div className="row">
        <div className="col-12 col-md-6 d-flex">
          <ExInput
            type={`text`}
            value={limit}
            label={`최대 설정 가능 해시태그 개수`}
            errorMessage={error.limit}
            onChange={(e) => {
              if (
                (isNumber(e.target.value) && e.target.value) ||
                !e.target.value
              ) {
                setLimit(e.target.value);
              }
              if (!e.target.value) {
                return setError({
                  ...error,
                  limit: `Maximum number of hashtags is required`
                });
              } else {
                return setError({
                  ...error,
                  limit: ""
                });
              }
            }}
          />
          <Button
            type={`submit`}
            className={`text-black m10-left fw-bold mt-auto d-flex align-items-center justify-content-center`}
            style={{
              height: "40px",
              marginBottom: "5px",
              width: "100px"
            }}
            text={`저장`}
            onClick={onsubmit}
          />
        </div>
      </div>
      <div className="inputData">
        <span>해시태그</span>
        <svg
          className="h-6 text-gray-800 w-6 cursor-pointer dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#1ebc1e"
          viewBox="0 0 24 24"
          onClick={handleOpenAddCategory}
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="card">
        <div className="card-body">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="hashtags">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {data.map((hashTag, index) => (
                    <Draggable
                      key={hashTag.id}
                      draggableId={hashTag.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="row mb-4"
                        >
                          <div className="d-flex inputData align-items-center">
                            <span>{hashTag.hashTagCategory}</span>
                            <Button
                              type={`submit`}
                              className={`text-black m10-left fw-bold mt-auto d-flex align-items-center justify-content-center`}
                              style={{
                                height: "40px",
                                marginBottom: "5px",
                                width: "100px"
                              }}
                              text={`설정`}
                              onClick={() => handleOpenAddHashTag(hashTag)}
                            />
                          </div>
                          <div className="col-12 row">
                            {hashTag.hashTags.map((tag) => (
                              <div className="col-4" key={tag.id}>
                                <ExInput
                                  type={`text`}
                                  label={``}
                                  newClass={"bg-gray"}
                                  value={tag.hashTag}
                                  disabled={true}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex inputData align-items-center">
          <span>추천 해시태그</span>
          <Button
            type={`submit`}
            className={`text-black m10-left fw-bold mt-auto d-flex align-items-center justify-content-center`}
            style={{
              height: "40px",
              marginBottom: "5px",
              width: "100px"
            }}
            text={`설정`}
            onClick={handleOpenAddRecommend}
          />
        </div>
        <div className="col-12 row">
          {recommnedData.map((category) => (
            <React.Fragment key={category.id}>
              {category.hashTags.map((tag) => (
                <div className="col-4" key={tag.id}>
                  <ExInput
                    type={`text`}
                    label={``}
                    newClass={"bg-gray"}
                    value={tag.hashTag}
                    disabled={true}
                  />
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
