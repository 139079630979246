// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "../../extras/Button";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../extras/Title";
import {
  getSetting,
  handleSetting,
  maintenanceMode,
  updateSetting
} from "../../../redux/slice/settingSlice";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { ExInput, Textarea } from "../../extras/Input";
import { uploadFile } from "../../../redux/api";

const Setting = (props) => {
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state.setting);

  const [youtube, setYoutube] = useState({
    name: "YOUTUBE",
    address: ""
  });
  const [naver, setNaver] = useState({
    name: "BLOG",
    address: ""
  });
  const [instagram, setInstagram] = useState({
    name: "INSTAGRAM",
    address: ""
  });
  const [popupText, setPopupText] = useState();
  const [image, setImage] = useState();
  const [imagePath, setImagePath] = useState("");

  const [error, setError] = useState({
    youtube: "",
    instagram: "",
    naver: "",
    popupText: "",
    image: ""
  });

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  useEffect(() => {
    if (setting) {
      const snsAddresses = setting?.snsAddresses || [];
      setYoutube(
        snsAddresses.find((item) => item.name === "YOUTUBE") || {
          name: "YOUTUBE",
          address: ""
        }
      );
      setInstagram(
        snsAddresses.find((item) => item.name === "INSTAGRAM") || {
          name: "INSTAGRAM",
          address: ""
        }
      );
      setNaver(
        snsAddresses.find((item) => item.name === "BLOG") || {
          name: "BLOG",
          address: ""
        }
      );
      setPopupText(setting.pointInformationMessage);
      setImage(setting.imageBartenderRegistrationUrl);
      setImagePath(setting.imageBartenderRegistrationUrl);
    }
  }, [setting]);

  useEffect(() => {
    if (setting) {
    }
  }, [setting]);

  const onsubmit = async (e) => {
    e.preventDefault();

    if (
      !youtube.address ||
      !instagram.address ||
      !naver.address ||
      !popupText ||
      !image
    ) {
      let error = {};
      if (!youtube.address) error.youtube = "YouTube 링크가 필요합니다.";
      if (!instagram.address) error.instagram = "Instagram 링크가 필요합니다.";
      if (!naver.address) error.naver = "블로그 링크가 필요합니다.";
      if (!popupText)
        error.popupText = "포인트 가이드 팝업 텍스트가 필요합니다.";
      if (!image) error.image = "이미지가 필요합니다.";

      return setError({ ...error });
    } else {
      // upload thumbnail file
      let thumbnail = "";
      if (typeof image === "string") {
        thumbnail = image;
      } else {
        const formDataMainImage = new FormData();
        formDataMainImage.append("file", image);
        const mainImageRes = await uploadFile(formDataMainImage);
        thumbnail = mainImageRes.data.filepath;
      }
      const snsAddress = [
        {
          name: youtube.name,
          address: youtube.address
        },
        {
          name: naver.name,
          address: naver.address
        },
        {
          name: instagram.name,
          address: instagram.address
        }
      ];
      const body = {
        snsAddress: snsAddress,
        pointInformationMessage: popupText,
        imageBartenderRegistrationUrl: thumbnail
      };
      const res = await dispatch(updateSetting(body));
      console.log("res", res);
      if (res?.data?.data) {
        dispatch(getSetting());
      }
    }
  };

  const handleImage = (e) => {
    if (!e.target.files) {
      setError((prevErrors) => ({
        ...prevErrors,
        image: "이미지가 필요합니다."
      }));
    }
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
    setError((prevErrors) => ({
      ...prevErrors,
      image: ""
    }));
  };

  const handleSetError = (field, value) => {
    let newError = {};
    if (field === "youtube") {
      newError.youtube = !value ? "YouTube 링크가 필요합니다." : "";
    }
    if (field === "instagram") {
      newError.instagram = !value ? "Instagram 링크가 필요합니다." : "";
    }
    if (field === "naver") {
      newError.naver = !value ? "블로그 링크가 필요합니다." : "";
    }
    if (field === "popupText") {
      newError.popupText = !value
        ? "포인트 가이드 팝업 텍스트가 필요합니다."
        : "";
    }
    setError((preState) => ({ ...error, ...newError }));
  };

  return (
    <div className="mainSetting">
      <Title name="소개 설정" />
      <div className="settingBox">
        <p className="fs-18">바텐더 등록 페이지</p>
        {/* <div className="row">
          <div className="col-12 col-md-6 mt-3">
            <div className="settingBoxOuter">
              <div className="d-flex justify-content-between settingBoxHeader">
                <h4>App Setting</h4>
                <div className="inputData">
                  <label className="me-2">Maintenance Mode</label>
                  <ToggleSwitch
                    onClick={() => handleAppActive(setting?._id)}
                    value={setting?.maintenanceMode}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="flex-row inputData justify-content-start text-start text">
                  <label htmlFor="privacyPolicyLink" className="order-1 ms-2">
                    Privacy Policy Link (redirect user to this link from app)
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="privacyPolicyLink"
                    value={privacyPolicyLink}
                    placeholder="Enter privacyPolicyLink"
                    onChange={(e) => {
                      setPrivacyPolicyLink(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          privacyPolicyLink: ` privacyPolicyLink Is Required`
                        });
                      } else {
                        return setError({
                          ...error,
                          privacyPolicyLink: ""
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="text-start errorMessage">
                      {error && error?.privacyPolicyLink}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="flex-row inputData justify-content-start text-start text">
                  <label htmlFor="tnc" className="order-1 ms-2">
                    Terms And Condition
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="tnc"
                    value={tnc}
                    placeholder="Enter tnc"
                    onChange={(e) => {
                      setTnc(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          tnc: ` Terms And Condition Is Required`
                        });
                      } else {
                        return setError({
                          ...error,
                          tnc: ""
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="text-start errorMessage">
                      {error && error?.tnc}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="flex-row inputData justify-content-start text-start text">
                  <label htmlFor="tax" className="order-1 ms-2">
                    Tax (%)
                  </label>
                  <input
                    type="number"
                    className="rounded-2"
                    id="tax"
                    value={tax}
                    placeholder="Enter tax"
                    onChange={(e) => {
                      setTax(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          tax: ` tax Is Required`
                        });
                      } else {
                        return setError({
                          ...error,
                          tax: ""
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="text-start errorMessage">
                      {error && error?.tax}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mt-3">
            <div className="settingBoxOuter">
              <div className="settingBoxHeader">
                <h4>Razor Pay Setting</h4>
              </div>
              <div className="col-12">
                <div className="flex-row inputData justify-content-start text-start text">
                  <label className="my-3">
                    Razor Pay Active (enable/disable razor pay)
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="razorSecretKey"
                    value={razorSecretKey}
                    placeholder="Enter Razorpay Secret Key"
                    onChange={(e) => {
                      setRazorSecretKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          razorSecretKey: ` Razorpay Secret Key Is Required`
                        });
                      } else {
                        return setError({
                          ...error,
                          razorSecretKey: ""
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="text-start errorMessage">
                      {error && error?.razorSecretKey}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="flex-row inputData justify-content-start text-start text">
                  <label htmlFor="razorPayId" className="order-1 ms-2">
                    RazorPay Id
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="razorPayId"
                    value={razorPayId}
                    placeholder="Enter razorPay Id"
                    onChange={(e) => {
                      setRazorPayId(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          razorPayId: ` razorPay Id Is Required`
                        });
                      } else {
                        return setError({
                          ...error,
                          razorPayId: ""
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="text-start errorMessage">
                      {error && error?.razorPayId}
                    </p>
                  )}
                </div>
              </div>
              <div className="inputData">
                <div>
                  <label className="my-3">Razor Pay Active</label>
                </div>
                <ToggleSwitch
                  onClick={() => handleSettingSwitch(setting?._id, 1)}
                  value={setting?.isRazorPay}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mt-3">
            <div className="settingBoxOuter">
              <div className="settingBoxHeader">
                <h4>Stripe Pay Setting</h4>
              </div>
              <div className="col-12">
                <div className="flex-row inputData justify-content-start text-start text">
                  <label
                    htmlFor="stripePublishableKey"
                    className="order-1 ms-2"
                  >
                    Stripe Publishable Key
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="stripePublishableKey"
                    value={stripePublishableKey}
                    placeholder="Enter Stripe Publishable Key"
                    onChange={(e) => {
                      setStripePublishableKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          stripePublishableKey: ` stripePublishableKey Is Required`
                        });
                      } else {
                        return setError({
                          ...error,
                          stripePublishableKey: ""
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="text-start errorMessage">
                      {error && error?.stripePublishableKey}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="flex-row inputData justify-content-start text-start text">
                  <label htmlFor="stripeSecretKey" className="order-1 ms-2">
                    Stripe Secret Key
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="stripeSecretKey"
                    value={stripeSecretKey}
                    placeholder="Enter Stripe Secret Key"
                    onChange={(e) => {
                      setStripeSecretKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          stripeSecretKey: ` Stripe Secret Key Is Required`
                        });
                      } else {
                        return setError({
                          ...error,
                          stripeSecretKey: ""
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="text-start errorMessage">
                      {error && error?.stripeSecretKey}
                    </p>
                  )}
                </div>
              </div>
              <div className="inputData">
                <div>
                  <label className="my-3">
                    Stripe Pay Active (enable/disable stripe pay)
                  </label>
                </div>
                <ToggleSwitch
                  onClick={() => handleSettingSwitch(setting?._id, 2)}
                  value={setting?.isStripePay}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <div className="settingBoxOuter">
              <div className="settingBoxHeader">
                <h4>Currency Setting</h4>
              </div>

              <div className="col-12">
                <div className="flex-row inputData justify-content-start text-start text">
                  <label htmlFor="currencyName" className="order-1 ms-2">
                    Currency Name
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="currencyName"
                    value={currencyName}
                    placeholder="Enter currency Name"
                    onChange={(e) => {
                      setCurrencyName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          currencyName: ` currency Name Is Required`
                        });
                      } else {
                        return setError({
                          ...error,
                          currencyName: ""
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="text-start errorMessage">
                      {error && error?.currencyName}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="flex-row inputData justify-content-start text-start text">
                  <label htmlFor="currencySymbol" className="order-1 ms-2">
                    Currency Symbol
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="currencySymbol"
                    value={currencySymbol}
                    placeholder="Enter Currency Symbol"
                    onChange={(e) => {
                      setCurrencySymbol(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          currencySymbol: ` Currency Symbol Is Required`
                        });
                      } else {
                        return setError({
                          ...error,
                          currencySymbol: ""
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="text-start errorMessage">
                      {error && error?.currencySymbol}
                    </p>
                  )}
                </div>
              </div>
              <div className="inputData">
                <div>
                  <label className="my-3">Cash After Service</label>
                </div>
                <ToggleSwitch
                  onClick={() => handleSettingSwitch(setting?._id, 5)}
                  value={setting?.cashAfterService}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <div className="settingBoxOuter">
              <div className="settingBoxHeader">
                <h4>Firebase Notification Setting</h4>
              </div>

              <div className="flex-row inputData justify-content-start text-start text">
                <label className="float-left" htmlFor="firebaseKey">
                  Private Key JSON (use for firebase push notification in app)
                </label>
                <textarea
                  name="firebaseKey"
                  className="mt-2"
                  id="firebaseKey"
                  rows={10}
                  value={firebaseKey}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    try {
                      const newData = JSON.parse(newValue);
                      setfirebaseKey(newValue);
                      setError("");
                    } catch (error) {
                      // Handle invalid JSON input
                      console.error("Invalid JSON input:", error);
                      setfirebaseKey(newValue);
                      return setError({
                        ...error,
                        firebaseKey: "Invalid JSON input"
                      });
                    }
                  }}
                ></textarea>

                {error.firebaseKey && (
                  <div className="text-left pl-1">
                    <p className="errorMessage">{error.firebaseKey}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <div className="settingBoxOuter">
              <div className="settingBoxHeader">
                <h4>Flutter Wave Setting</h4>
              </div>

              <div className="col-12">
                <div className="flex-row inputData justify-content-start text-start text">
                  <label htmlFor="flutterWaveKey" className="order-1 ms-2">
                    FlutterWave Key
                  </label>
                  <input
                    type="text"
                    className="rounded-2"
                    id="flutterWaveKey"
                    value={flutterWaveKey}
                    placeholder="EnterFlutterWave Key"
                    onChange={(e) => {
                      setFlutterWaveKey(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          flutterWaveKey: `FlutterWave Key Is Required`
                        });
                      } else {
                        return setError({
                          ...error,
                          flutterWaveKey: ""
                        });
                      }
                    }}
                  />
                  {error && (
                    <p className="text-start errorMessage">
                      {error && error?.flutterWaveKey}
                    </p>
                  )}
                </div>
              </div>
              <div className="inputData">
                <div>
                  <label className="my-3">
                    flutterwawe payment (enable/disable)
                  </label>
                </div>
                <ToggleSwitch
                  onClick={() => handleSettingSwitch(setting?._id, 4)}
                  value={setting?.isFlutterWave}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-12">
            <ExInput
              type={`text`}
              value={youtube.address}
              placeholder={"https://youtube.com"}
              label={`소개 영상 유튜브`}
              errorMessage={error.youtube}
              onChange={(e) => {
                setYoutube({ ...youtube, address: e.target.value });
                handleSetError("youtube", e.target.value);
              }}
            />
          </div>
          <div className="col-12">
            <ExInput
              type={`text`}
              value={instagram.address}
              label={`인스타그램`}
              placeholder={"https://www.instagram.com"}
              errorMessage={error.instagram}
              onChange={(e) => {
                setInstagram({ ...instagram, address: e.target.value });
                handleSetError("instagram", e.target.value);
              }}
            />
          </div>
          <div className="col-12">
            <ExInput
              type={`text`}
              value={naver.address}
              label={`네이버 블로그`}
              placeholder={"https://blog.naver.com"}
              errorMessage={error.naver}
              onChange={(e) => {
                setNaver({ ...naver, address: e.target.value });
                handleSetError("naver", e.target.value);
              }}
            />
          </div>
          <div className="col-md-6">
            <div className="flex-row inputData justify-content-start text-start file">
              <label htmlFor="name">사진</label>
              <div className="position-relative">
                <input
                  type="file"
                  className="position-absolute bottom-0 end-0 mt-auto opacity-0 start-0"
                  id="image"
                  // value={name}
                  onChange={(e) => {
                    handleImage(e);
                    e.target.value = null;
                  }}
                  style={{
                    height: "40px"
                  }}
                  accept="image/*"
                />
                <div
                  className="d-flex border rounded-2"
                  style={{
                    height: "40px"
                  }}
                >
                  <div
                    className="d-flex align-items-center bg-gray justify-content-center rounded-start-2 text-white"
                    style={{
                      height: "100%",
                      width: "30%"
                    }}
                  >
                    파일 선택
                  </div>
                  <div
                    className="d-flex align-items-center p-1"
                    style={{ width: "70%" }}
                  >
                    <span className="text-truncate">{image?.name}</span>
                  </div>
                </div>
              </div>
              {error && (
                <p className="text-start errorMessage">
                  {error && error?.image}
                </p>
              )}
              {imagePath && (
                <div className="image-start">
                  <img
                    src={imagePath}
                    alt="ServiceImage"
                    draggable="false"
                    className={`${
                      (!imagePath || imagePath === "") && "d-none"
                    }`}
                    width={"100px"}
                    height={"100px"}
                    // data-image={name}
                    data-class={`showImage`}
                  />
                </div>
              )}
            </div>
          </div>
          <p className="fs-18">기타 팝업 문구</p>
          <div className="col-12">
            <Textarea
              // value={dialogueData?.details}
              value={popupText}
              label={`포인트 안내 팝업 문구`}
              placeholder={``}
              errorMessage={error.popupText}
              classError={"errorMessage"}
              onChange={(e) => {
                setPopupText(e.target.value);
                handleSetError("popupText", e.target.value);
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div className="formFooter">
            <Button
              type={`submit`}
              className={`text-light m10-left fw-bold`}
              text={`저장`}
              style={{ backgroundColor: "#1ebc1e" }}
              onClick={onsubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Setting;
