/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import Button from "../../extras/Button";
import Table from "./Table";
import Pagination from "../../extras/Pagination";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import { confirm } from "../../../util/Alert";
import {
  deleteProduct,
  getAllProducts
} from "../../../redux/slice/serviceSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddStockModal from "./AddStockModal";
import { SuccessStatusCode } from "../../../util/constants";

const mapTypeToKo = {
  WHISKY: "양주",
  BEER: "맥주",
  WINE: "와인",
  CHAMPAGNE: "샴페인",
  SIDE_DISH: "안주",
  BEVERAGE: "음료"
};

const StockList = (props) => {
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const { products, total } = useSelector((state) => state.service);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("ALL");

  const payload = {
    start: page,
    limit: rowsPerPage,
    search
  };

  const fetchData = () => {
    dispatch(getAllProducts(props.storeId));
  };

  useEffect(() => {
    if (props.tabActive === "stock") {
      fetchData();
    }
  }, [props.storeId, props.tabActive]);

  useEffect(() => {
    setData(products);
  }, [products]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  function handleOpenEdit(id) {
    const data = products.find((item) => item.id === id);
    dispatch(
      openDialog({
        type: "addStock",
        data: { data: data, storeId: props.storeId, stockId: id }
      })
    );
  }

  const serviceTable = [
    {
      Header: "No",
      Cell: ({ index }) => <span>{index + 1}</span>
    },
    {
      tdClass: "colOpitonName",
      Header: "상품 이름",
      Cell: ({ row }) => <span className="text-capitalize">{row?.name}</span>
    },
    {
      Header: "분류",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.category ? mapTypeToKo[row?.category] : ""}
        </span>
      )
    },
    {
      Header: "구매 가격",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.originalPrice}원</span>
      )
    },

    {
      Header: "판매 금액",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.salePrice}원</span>
      )
    },
    {
      Header: "재고",
      Cell: ({ row }) => <span className="text-capitalize">{row?.stock}개</span>
    },
    {
      Header: "수정",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenEdit(row.id)}
        >
          수정
        </button>
      )
    },
    {
      Header: "삭제",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleDelete(row.id)}
        >
          삭제
        </button>
      )
    }
  ];

  const handleDelete = (id) => {
    // const data = warning("Delete");
    const data = confirm({
      title: "재고 삭제",
      text: "해당 상품을 삭제하시겠습니까?",
      confirm: "확인",
      cancle: "취소"
    });
    data
      .then(async (confirm) => {
        const yes = confirm.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          const res = await dispatch(deleteProduct(id));
          if (res.payload?.statusCode === SuccessStatusCode) {
            fetchData();
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="mainCategory">
      <div className="row">
        <div className="col-3">
          <Button
            className={`bg-button p-10 text-white m10-bottom `}
            // bIcon={`fa-solid fa-user-plus`}
            text="재고 추가"
            onClick={() => {
              dispatch(
                openDialog({
                  type: "addStock",
                  data: {
                    storeId: props.storeId
                  }
                })
              );
            }}
          />
        </div>
      </div>

      <div>
        <Table
          data={data}
          mapData={serviceTable}
          serverPerPage={rowsPerPage}
          classWrapper={"mainTable tableServices"}
          Page={page}
        />
        <Pagination
          type={"server"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
      {dialogue && dialogueType === "addStock" && (
        <AddStockModal refetch={fetchData} />
      )}
    </div>
  );
};

export default StockList;
