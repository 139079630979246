import React, { useEffect, useState } from "react";
import { ExInput, SelectV2, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import { addFaq, updateFaq } from "../../../redux/slice/faqSlice";
import { SuccessStatusCode } from "../../../util/constants";
import { useRef } from "react";

const options = [
  {
    value: "RESERVATION",
    name: "예약"
  },
  {
    value: "PAYMENT",
    name: "결제"
  },
  {
    value: "BARTENDER",
    name: "바텐더"
  }
];

const AddFAQModal = (props) => {
  const dispatch = useDispatch();
  const loadingRef = useRef(false);
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const [type, setType] = useState("");
  const [error, setError] = useState({
    type: "",
    title: "",
    content: ""
  });

  useEffect(() => {
    console.log("dialogueData", dialogueData);
    if (dialogueData) {
      setTitle(dialogueData?.title);
      setContent(dialogueData?.content);
      setType(dialogueData?.category);
    }
  }, [dialogueData]);

  const handleSubmit = async () => {
    loadingRef.current = true;
    try {
      if (!type) {
        let error = {};
        if (!type) error.type = "유형이 필요합니다";
        loadingRef.current = false;
        return setError({ ...error });
      } else {
        const body = {
          category: type,
          title: title || "",
          content: content || ""
        };
        console.log("body", body);
        if (dialogueData?.id) {
          let payload = {
            body,
            id: dialogueData.id
          };
          const res = await dispatch(updateFaq(payload));
          if (res.payload?.statusCode === SuccessStatusCode) {
            loadingRef.current = false;
            dispatch(closeDialog());
            props.refetch && props.refetch();
          }
        } else {
          const res = await dispatch(addFaq(body));
          if (res.payload?.data?.data) {
            loadingRef.current = false;
            dispatch(closeDialog());
            props.refetch && props.refetch();
          }
        }
      }
    } catch (err) {
      loadingRef.current = false;
      console.log("err.message", err);
    }
  };

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-11 col-md-6">
            <div class="mainDiaogBox">
              <div class="row formHead align-items-center justify-content-between">
                <div className="col-8">
                  <h2 className="text-theme m0">
                    FAQ {!dialogueData ? "등록" : "수정"}
                  </h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row formBody align-items-start">
                <div className="col-12 inputData">
                  <lable>FAQ 카테고리</lable>
                  <SelectV2
                    option={options}
                    value={type}
                    onChange={(value) => setType(value)}
                    placeholder={"카테고리를 선택하세요"}
                    errorMessage={error.type}
                  />
                </div>
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"FAQ 제목"}
                    value={title}
                    onPaste={(event) => {
                      const pastedData = event.clipboardData.getData("Text");
                      if (pastedData.length > 40) {
                        event.preventDefault();
                        setTitle(pastedData.slice(0, 40));
                      } else {
                        setTitle(pastedData);
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value.length <= 40) {
                        setTitle(e.target.value);
                      }
                    }}
                    placeholder={"제목을 입력하세요. (공백 포함 40자 이내)"}
                  />
                </div>
                <div className="col-12">
                  <Textarea
                    value={content}
                    row={5}
                    onPaste={(event) => {
                      const pastedData = event.clipboardData.getData("Text");
                      if (pastedData.length > 200) {
                        event.preventDefault();
                        setContent(pastedData.slice(0, 200));
                      } else {
                        setContent(pastedData);
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value.length <= 200) {
                        setContent(e.target.value);
                      }
                    }}
                    label={`FAQ 내용`}
                    placeholder={`내용을 입력하세요. (공백 포함 200자 이내)`}
                  />
                </div>
              </div>

              <div className="col-12 d-flex align-items-center justify-content-end gap-2">
                <Button
                  className={`bg-gray text-light`}
                  text={`취소`}
                  type={`button`}
                  onClick={() => dispatch(closeDialog())}
                />
                <Button
                  type={`submit`}
                  className={`text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`등록`}
                  disabled={loadingRef.current}
                  onClick={() => handleSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFAQModal;
