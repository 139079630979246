import React, { useCallback, useEffect, useRef, useState } from "react";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Table from "../../extras/Table";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extras/Button";
import { ExInput } from "../../extras/Input";
import UserSearchModal from "./UserSearchModal";
import { getAllUsers } from "../../../redux/slice/userSlice";
import Pagination from "../../extras/Pagination";
import { toast } from "react-toastify";
import {
  addLeader,
  getAllLeaderMembers,
  updateLeader
} from "../../../redux/slice/leaderSlice";
import { isNumber } from "../../../util/fuction";
import { useNavigate } from "react-router-dom";
import { DangerRight } from "../../api/toastServices";

const handleDataMember = (selectedMember, percentageValues) => {
  return selectedMember.map((item) => {
    const settlementPercentage =
      percentageValues.find((value) => value.id === item)?.value || 0;
    return {
      memberId: item,
      settlementPercentage: Number(settlementPercentage)
    };
  });
};

export default function AddLeaderModal(props) {
  const values = useRef(null);
  const oldValues = useRef(null);
  const loadingRef = useRef(false);
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [type, setType] = useState("MANAGEMENT");
  const [leader, setLeader] = useState(null);

  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);

  const fetchMember = useCallback(async (id) => {
    const res = await dispatch(getAllLeaderMembers(id));
    const members = res?.payload?.data || [];
    setSelected(members.map((item) => item.id));
    const percentStage = members.map((item) => ({
      id: item.id,
      value: item.settlementPercentage
    }));
    values.current = percentStage;
    oldValues.current = percentStage;
  }, []);

  const fetchData = useCallback(async () => {
    const payload = {
      isBartender: type === "MANAGEMENT" ? "true" : "false"
    };
    const res = await dispatch(getAllUsers(payload));
    const data = leader?.id
      ? (res.payload?.data || []).filter((item) => item.id !== leader.id)
      : res.payload?.data || [];
    setData(data);
    setTotal(data.length);
  }, [dispatch, type, leader?.id]);

  console.log("leader?.id", leader?.id);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  function handleSelect(e, id) {
    if (e.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((item) => item !== id));
    }
  }

  function handleSelectAll(e) {
    if (e.target.checked) {
      setSelected(data.map((item) => item.id));
    } else {
      setSelected([]);
    }
  }

  useEffect(() => {
    if (dialogueData && dialogueData?.leaderId) {
      fetchMember(dialogueData?.leaderId);
      setType(dialogueData?.classification);
      setLeader(dialogueData);
    }
  }, [dialogueData, fetchMember]);

  const handleChangePercent = (e, id) => {
    let newData = values.current || [];
    const findIndex = newData.findIndex((item) => item.id === id);
    let numberOnly = false;
    if ((isNumber(e.target.value) && e.target.value) || !e.target.value) {
      numberOnly = true;
    }
    console.log("numberOnly", numberOnly);
    const inputElement = document.getElementById(`${id}`);

    console.log("inputElement", inputElement);

    if (findIndex === -1) {
      newData = [
        ...newData,
        {
          id,
          value: numberOnly ? e.target.value : ""
        }
      ];
      if (inputElement) {
        inputElement.value = numberOnly ? e.target.value : "";
      }
    } else {
      newData[findIndex] = {
        ...newData[findIndex],
        value: numberOnly ? e.target.value : newData[findIndex].value
      };
      if (inputElement) {
        inputElement.value = numberOnly
          ? e.target.value
          : newData[findIndex].value;
      }
    }
    values.current = newData;
  };

  const handleInfo = (row) => {
    navigate("/admin/user/userProfile", {
      state: {
        row
      }
    });
  };

  const mapData = [
    {
      Header: (
        <div className="d-flex align-items-center justify-content-center">
          <input
            type="checkbox"
            style={{ height: "17px", width: "17px" }}
            checked={selected.length === data.length}
            onChange={handleSelectAll}
          />
        </div>
      ),
      Cell: ({ row }) => (
        <div className="d-flex align-items-center justify-content-center">
          <input
            type="checkbox"
            style={{ height: "17px", width: "17px" }}
            checked={selected.includes(row?.id)}
            onChange={(e) => {
              if (row?.members?.includes(leader?.id)) {
                DangerRight("해당 유저의 팀장은 팀원으로 등록할 수 없습니다.");
                return;
              }
              handleSelect(e, row?.id);
            }}
          />
        </div>
      )
    },
    {
      Header: "이름",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleInfo(row)}
        >
          {row?.fullname}
        </button>
      )
    },
    {
      Header: "닉네임",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleInfo(row)}
        >
          {row?.nickname}
        </button>
      )
    },
    {
      Header: `휴대폰 번호`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.phoneNumber}</span>
      )
    },
    {
      Header: `바텐더 등록 여부`,
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.isBartender ? "등록" : "미등록"}
        </span>
      )
    },
    {
      Header: `정산 % 입력`,
      Cell: ({ row }) => (
        <>
          {selected.includes(row?.id) ? (
            <input
              key={row.id}
              type={"text"}
              id={row?.id}
              defaultValue={
                values.current?.find((item) => item.id === row.id)?.value ||
                row?.settlementPercent
              }
              className="rounded width-70 text-center"
              onChange={(e) => {
                handleChangePercent(e, row?.id);
              }}
            />
          ) : (
            <span className="text-capitalize">
              {values.current?.find((item) => item.id === row.id)?.value ||
              row?.settlementPercent
                ? (values.current?.find((item) => item.id === row.id)?.value ||
                    row?.settlementPercent) + "%"
                : ""}
            </span>
          )}
        </>
      )
    }
  ];

  const handleDataUpdate = () => {
    const oldValuesMembers = oldValues.current || [];
    const updateData = oldValuesMembers
      .filter((item) => selected.includes(item.id))
      .map((item) => ({
        memberId: item.id,
        settlementPercentage: Number(item.value),
        action: "UPDATE"
      }));

    const newValuesMembers = values.current || [];
    const newData = selected
      .filter(
        (item) => !oldValuesMembers.find((oldItem) => oldItem.id === item)
      )
      .map((item) => {
        const settlementPercentage =
          newValuesMembers.find((value) => value.id === item)?.value || 0;
        return {
          memberId: item,
          settlementPercentage: Number(settlementPercentage),
          action: "CREATE"
        };
      });

    const deleteData = oldValuesMembers
      .filter((item) => !selected.includes(item.id))
      .map((item) => ({
        memberId: item.id,
        settlementPercentage: Number(item.value),
        action: "DELETE"
      }));
    return [...updateData, ...deleteData, ...newData];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!leader) {
      DangerRight("리더를 선택해주세요.");
      return;
    }
    // if (!selected.length) {
    //   DangerRight("팀원을 선택해주세요.");
    //   return;
    // }
    try {
      loadingRef.current = true;
      const members = handleDataMember(selected, values.current || []);
      let body = {
        classification: type,
        members
      };
      console.log("body", body);
      if (dialogueData?.leaderId) {
        let payload = {
          body: { ...body, members: handleDataUpdate() },
          id: dialogueData.leaderId
        };
        const res = await dispatch(updateLeader(payload));
        if (res?.payload?.data?.data) {
          dispatch(closeDialog());
          props.refetch && props.refetch();
          loadingRef.current = false;
        }
      } else {
        const res = await dispatch(addLeader({ ...body, leaderId: leader.id }));
        if (res?.payload?.data?.data) {
          dispatch(closeDialog());
          props.refetch && props.refetch();
          loadingRef.current = false;
        }
      }
    } catch (error) {
      console.log("error", error);
      loadingRef.current = false;
    }
    loadingRef.current = false;
  };

  return (
    <>
      <div className="dialog">
        <div className="w-100">
          <div className="row justify-content-center">
            <div className="col-11">
              <div className="mainDiaogBox position-relative">
                <div className="row justify-content-between align-items-center formHead">
                  <div className="col-8">
                    <h4 className="text-theme m0">팀장 추가</h4>
                  </div>
                  <div className="col-4">
                    <div
                      className="closeButton"
                      onClick={() => {
                        dispatch(closeDialog());
                      }}
                    >
                      <i className="ri-close-line"></i>
                    </div>
                  </div>
                </div>
                <div
                  className="row align-items-start formBody"
                  style={{ maxHeight: "calc(100svh - 200px)" }}
                >
                  <div className="col-6 d-flex">
                    <ExInput
                      type={`text`}
                      label={"이름"}
                      value={leader?.nickname || leader?.leaderName || ""}
                      disabled
                    />
                    {!dialogueData && (
                      <Button
                        type={`submit`}
                        className={`text-black m10-left d-flex align-items-center justify-content-center mt-auto`}
                        style={{
                          backgroundColor: "#E7E6E6",
                          height: "40px",
                          width: "120px",
                          minWidth: "120px",
                          marginBottom: "5px"
                        }}
                        text={`검색`}
                        onClick={() => setOpenSearchModal(true)}
                      />
                    )}
                  </div>
                  <div
                    className="d-flex align-items-center inputData gap-4"
                    style={{ marginTop: "5px", height: "40px" }}
                  >
                    <label>분류</label>
                    <ExInput
                      type={`radio`}
                      label={`관리팀장`}
                      name={"type"}
                      value={`MANAGEMENT`}
                      checked={type === "MANAGEMENT" && true}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    />

                    <ExInput
                      type={`radio`}
                      label={`영업팀장`}
                      name={"type"}
                      value={`MARKETING`}
                      checked={type === "MARKETING" && true}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    />
                  </div>
                  <p className="mb-4 titleTable">인원 등록</p>
                  <div className="col-12">
                    <Table
                      data={data}
                      mapData={mapData}
                      classWrapper={"mainTable mainTableModal"}
                      PerPage={rowsPerPage}
                      Page={page}
                      type={"client"}
                    />
                    <div className="d-flex justify-content-end m10-top">
                      <Pagination
                        type={"client"}
                        serverPage={page}
                        setServerPage={setPage}
                        serverPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        totalData={total}
                        hideSelect
                      />
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-center align-items-center gap-4">
                    <Button
                      className={`bg-gray text-light`}
                      text={`취소`}
                      type={`button`}
                      onClick={() => dispatch(closeDialog())}
                    />
                    <Button
                      type={`submit`}
                      className={` text-white m10-left`}
                      style={{ backgroundColor: "#1ebc1e" }}
                      text={`저장`}
                      disabled={loadingRef.current}
                      onClick={(e) => handleSubmit(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openSearchModal && (
        <UserSearchModal
          onClose={() => {
            setOpenSearchModal(false);
          }}
          onSelect={(item) => {
            setLeader(item);
          }}
        />
      )}
    </>
  );
}
