import React, { useEffect, useRef, useState } from "react";
import { ExInput, SelectV2, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import { SuccessStatusCode } from "../../../util/constants";
import { bulkUpdateHashTag } from "../../../redux/slice/hashTagSlice";

const AddTag = (props) => {
  const dispatch = useDispatch();
  const loadingRef = useRef(false);
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [hashTagList, setHashTagList] = useState([]);
  const [newCategory, setNewCategory] = useState("");

  useEffect(() => {
    if (dialogueData) {
      setHashTagList(dialogueData.hashTags);
    }
  }, [dialogueData]);

  const handleAddNew = () => {
    if (!newCategory) return;
    const newHashTagList = [
      {
        hashTag: newCategory
      },
      ...hashTagList
    ];
    setHashTagList([...newHashTagList]);
    setNewCategory("");
  };

  const handleRemoveCategory = (index) => {
    let newHashTagList = [...hashTagList];
    if (newHashTagList[index].id) {
      newHashTagList[index] = { ...newHashTagList[index], isDelete: true };
      setHashTagList(newHashTagList);
    } else {
      newHashTagList.splice(index, 1);
      setHashTagList(newHashTagList);
    }
  };

  const handleDataUpdate = () => {
    const updateData = hashTagList
      .filter((item) => item.id && !item.isDelete)
      .map((item, index) => ({
        ...item,
        order: index + 1
      }));

    const newData = hashTagList
      .filter((item) => !item.id)
      .map((item, index) => ({
        ...item,
        isRecommended: false,
        order: updateData.length + index + 1,
        hashTagCategoryId: dialogueData.id
      }));

    const deleteData = hashTagList
      .filter((item) => item.id && item.isDelete)
      .map((item) => item.id);
    return {
      hashtagsUpdate: updateData,
      hashTagDeleteIds: deleteData,
      hashtagsCreate: newData
    };
  };

  const handleSubmit = async () => {
    try {
      const body = {
        ...handleDataUpdate()
      };

      const res = await dispatch(bulkUpdateHashTag(body));

      if (res.payload?.statusCode === SuccessStatusCode) {
        setHashTagList([]);
        dispatch(closeDialog());
        props.refetch && props.refetch();
      }
    } catch (err) {
      loadingRef.current = false;
      console.log("err.message", err);
    }
    loadingRef.current = false;
  };

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-md-8 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">해시태그 설정</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="row">
                  {hashTagList.map((tag, index) => {
                    if (tag.isDelete) {
                      return null;
                    }
                    return (
                      <div
                        className="col-4 d-flex align-items-centetr"
                        key={tag?.id || index}
                      >
                        <ExInput
                          type={`text`}
                          label={``}
                          newClass={"bg-gray"}
                          value={tag.hashTag}
                          disabled={true}
                        />
                        <div
                          className="col-1 h-100  cursor-pointer"
                          style={{
                            marginTop: "5px",
                            width: "30px",
                            marginLeft: "10px"
                          }}
                          onClick={() => handleRemoveCategory(index)}
                        >
                          <div
                            className="rounded-2 d-flex align-items-center justify-content-center"
                            style={{
                              backgroundColor: "#fff1f1",
                              height: "40px"
                            }}
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="#f71845"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="col-12">
                <div className="d-flex align-items-center">
                  <ExInput
                    type={`text`}
                    label={``}
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                    placeholder="해시태그를 입력하세요."
                  />
                  <Button
                    className={`bg-gray text- ms-2`}
                    style={{ height: "40px" }}
                    text={`추가`}
                    type={`button`}
                    onClick={() => handleAddNew()}
                  />
                </div>
                <div className="d-flex justify-content-end align-items-center gap-2">
                  <Button
                    className={`bg-gray text-light`}
                    text={`취소`}
                    type={`button`}
                    onClick={() => dispatch(closeDialog())}
                  />
                  <Button
                    type={`submit`}
                    className={`text-white m10-left`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`저장`}
                    onClick={() => handleSubmit()}
                    disabled={loadingRef.current}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTag;
