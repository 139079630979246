import React, { useEffect, useRef, useState } from "react";
import { ExInput, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import { addNotice, updateNotice } from "../../../redux/slice/noticeSlice";
import { SuccessStatusCode } from "../../../util/constants";
import { uploadFile } from "../../../redux/api";

const AddNoticeModal = (props) => {
  const dispatch = useDispatch();
  const loadingRef = useRef(false);
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const [image, setImage] = useState();
  const [imagePath, setImagePath] = useState("");

  const [error, setError] = useState({
    title: "",
    content: "",
    image: ""
  });

  useEffect(() => {
    if (dialogueData) {
      setTitle(dialogueData?.title);
      setContent(dialogueData?.content);
      if (dialogueData?.imageUrl) {
        setImage(dialogueData?.imageUrl);
        setImagePath(dialogueData?.imageUrl);
      }
    }
  }, [dialogueData]);

  const handleImage = (e) => {
    if (!e.target.files) {
      setError((prevErrors) => ({
        ...prevErrors,
        image: "Image is Required"
      }));
    }
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
    setError((prevErrors) => ({
      ...prevErrors,
      image: ""
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!title || !content) {
        let error = {};
        if (!title) error.title = "제목은 필수입니다";
        if (!content) error.content = "내용은 필수입니다";

        return setError({ ...error });
      } else {
        loadingRef.current = true;
        let thumbnail = "";
        if (image) {
          if (typeof image === "string") {
            thumbnail = image;
          } else {
            const formImage = new FormData();
            formImage.append("file", image);
            const imageRes = await uploadFile(formImage);
            thumbnail = imageRes.data.filepath;
          }
        }
        const body = {
          title: title || "",
          content: content || "",
          type: "bartender",
          imageUrl: thumbnail
        };
        console.log("body", body);

        if (dialogueData?.noticeId) {
          let payload = {
            body,
            id: dialogueData.noticeId
          };
          const res = await dispatch(updateNotice(payload));
          if (res?.payload?.statusCode === SuccessStatusCode) {
            dispatch(closeDialog());
            props.refetch && props.refetch();
          }
          loadingRef.current = false;
        } else {
          const res = await dispatch(addNotice(body));
          if (res?.payload?.statusCode === SuccessStatusCode) {
            dispatch(closeDialog());
            props.refetch && props.refetch();
          }
          loadingRef.current = false;
        }
      }
    } catch (err) {
      console.log("err.message", err);
      loadingRef.current = false;
    }
    loadingRef.current = false;
  };

  return (
    <div className="dialog focusNone">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-11 col-md-6">
            <div className="mainDiaogBox">
              <div className="row formHead align-items-center justify-content-between">
                <div className="col-8">
                  <h2 className="text-theme m0">
                    게시물 {""}
                    {!dialogueData ? "등록" : "수정"}
                  </h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row formBody align-items-start">
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"게시물 제목"}
                    value={title}
                    onPaste={(event) => {
                      const pastedData = event.clipboardData.getData("Text");
                      if (pastedData.length > 40) {
                        event.preventDefault();
                        setTitle(pastedData.slice(0, 40));
                      } else {
                        setTitle(pastedData);
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value.length <= 40) {
                        setTitle(e.target.value);
                      }
                      if (!e.target.value) {
                        setError({
                          ...error,
                          title: "제목은 필수입니다"
                        });
                      } else {
                        setError({
                          ...error,
                          title: ""
                        });
                      }
                    }}
                    errorMessage={error.title}
                    placeholder={"제목을 입력하세요. (공백 포함 40자 이내)"}
                  />
                </div>
                <div className="col-12">
                  <Textarea
                    value={content}
                    row={5}
                    classError={"errorMessage"}
                    errorMessage={error.content}
                    onPaste={(event) => {
                      const pastedData = event.clipboardData.getData("Text");
                      if (pastedData.length > 200) {
                        event.preventDefault();
                        setContent(pastedData.slice(0, 200));
                      } else {
                        setContent(pastedData);
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value.length <= 200) {
                        setContent(e.target.value);
                      }
                      if (!e.target.value) {
                        setError({
                          ...error,
                          content: "내용은 필수입니다"
                        });
                      } else {
                        setError({
                          ...error,
                          content: ""
                        });
                      }
                    }}
                    label={`게시물 내용`}
                    placeholder={`내용을 입력하세요. (공백 포함 200자 이내)`}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="flex-row inputData justify-content-start text-start file">
                  <label htmlFor="name">파일</label>
                  <div className="position-relative">
                    <input
                      type="file"
                      className="position-absolute bottom-0 end-0 mt-auto opacity-0 start-0"
                      id="image"
                      // value={name}
                      onChange={(e) => {
                        handleImage(e);
                        e.target.value = null;
                      }}
                      style={{
                        height: "40px"
                      }}
                      accept="image/*"
                    />
                    <div
                      className="d-flex border rounded-2"
                      style={{
                        height: "40px"
                      }}
                    >
                      <div
                        className="d-flex align-items-center bg-gray justify-content-center rounded-start-2 text-white"
                        style={{
                          height: "100%",
                          width: "30%"
                        }}
                      >
                        파일 선택
                      </div>
                      <div
                        className="d-flex align-items-center p-1"
                        style={{ width: "70%" }}
                      >
                        <span className="text-truncate">{image?.name}</span>
                      </div>
                    </div>
                  </div>
                  {error && (
                    <p className="text-start errorMessage">
                      {error && error?.image}
                    </p>
                  )}
                  {imagePath && (
                    <div className="image-start">
                      <img
                        src={imagePath}
                        alt="ServiceImage"
                        draggable="false"
                        className={`${
                          (!imagePath || imagePath === "") && "d-none"
                        }`}
                        width={"100px"}
                        height={"100px"}
                        // data-image={name}
                        data-class={`showImage`}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12 d-flex align-items-center justify-content-end gap-2">
                <Button
                  className={`bg-gray text-light`}
                  text={`취소`}
                  type={`button`}
                  onClick={() => dispatch(closeDialog())}
                />
                <Button
                  type={`submit`}
                  className={`text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`등록`}
                  disabled={loadingRef.current}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNoticeModal;
