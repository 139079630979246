/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import Button from "../../extras/Button";
import Table from "./Table";
import Pagination from "../../extras/Pagination";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Title from "../../extras/Title";
import { confirm, warning } from "../../../util/Alert";
import {
  getAllServices,
  deleteService,
  serviceStatus
} from "../../../redux/slice/serviceSlice";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServiceDialogue from "./ServiceDialogue";
import Searching from "../../extras/Searching";
import { useLocation } from "react-router-dom";
import MenuList from "./MenuList";
import StockList from "./StockList";

const handleDataTable = (data = []) => {
  const rows = [];
  data.forEach((element, idx) => {
    const options = element.options || [];
    options.forEach((option, index) => {
      if (index === 0) {
        rows.push({
          type: element.type,
          menuName: element.menuName,
          optionsName: option.name,
          optionsPrice: option.price,
          optionsPaymentAmount: option.paymentAmount,
          optionsReserverTime: option.reserverTime,
          optionsStock: option.stock,
          id: element.id,
          rowSpan: options.length,
          isRender: true,
          index: idx + 1
        });
      } else {
        rows.push({
          type: "",
          menuName: "",
          optionsName: option.name,
          optionsPrice: option.price,
          optionsPaymentAmount: option.paymentAmount,
          optionsReserverTime: option.reserverTime,
          optionsStock: option.stock,
          isRender: false
        });
      }
    });
  });
  return rows;
};

const Service = () => {
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const { service, total } = useSelector((state) => state.service);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [data, setData] = useState([]);
  const [tabActive, setTabActive] = useState("menu");
  const [search, setSearch] = useState("ALL");

  const state = useLocation();

  const payload = {
    start: page,
    limit: rowsPerPage,
    search
  };

  const handleTabChange = (event) => {
    const activeTab = event.target.id;
    if (activeTab === "pills-home-tab") {
      setTabActive("menu");
    } else if (activeTab === "pills-profile-tab") {
      setTabActive("stock");
    }
  };

  // useEffect(() => {
  //   dispatch(getAllServices(payload));
  // }, [page, rowsPerPage, search]);

  // useEffect(() => {
  //   setData(service);
  // }, [service]);

  useEffect(() => {
    setData(handleDataTable(service));
  }, [service]);

  useEffect(() => {
    const tabElement = document.getElementById("pills-tab");

    if (tabElement) {
      tabElement.addEventListener("shown.bs.tab", handleTabChange);
    }

    return () => {
      if (tabElement) {
        tabElement.removeEventListener("shown.bs.tab", handleTabChange);
      }
    };
  }, []);

  return (
    <div className="mainCategory">
      <Title
        name={
          state?.state?.row?.storeName
            ? `스토어바 ${state ? state?.state?.row?.storeName : ""}역점 메뉴`
            : ""
        }
      />
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link servicesTab active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            메뉴 관리
          </button>
        </li>
        <li className="nav-item ms-2" role="presentation">
          <button
            className="nav-link servicesTab"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            재고 관리
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <MenuList
            storeId={state?.state?.row?.storeId}
            tabActive={tabActive}
          />
        </div>
        <div
          className="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <StockList
            storeId={state?.state?.row?.storeId}
            tabActive={tabActive}
          />
        </div>
      </div>
    </div>
  );
};

export default Service;
