import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { confirm, warning } from "../../../util/Alert";
import Title from "../../extras/Title";
import Pagination from "../../extras/Pagination";
import {
  activesalon,
  getAllSalons,
  salonDelete
} from "../../../redux/slice/salonSlice";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import CouponAddModal from "./CouponAddModal";
import IssueCouponModal from "./IssueCouponModal";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "./../../extras/Table";
import Button from "../../extras/Button";
import { deleteCoupon, getAllCoupon } from "./../../../redux/slice/couponSlice";

const typeSearch = [
  {
    value: "title",
    text: "제목"
  },
  {
    value: "startAt",
    text: "적용 시작일"
  },
  {
    value: "endAt",
    text: "적용 종료일"
  }
];

export const SearchingWithSelect = (props) => {
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [searchType, setSearchType] = useState("");
  const { handleSearch } = props;

  const handleSearchButton = () => {
    handleSearch(searchType, search, date);
  };

  return (
    <>
      <div className="">
        <div className="inputData inputDataSearch d-flex gap-4">
          <select
            value={searchType}
            className="inputTypeSearch"
            style={{
              height: "40px",
              marginTop: "0",
              width: "130px",
              minWidth: "130px"
            }}
            placeholder="선택"
            onChange={(e) => {
              if (e.target.value === "title") {
                setDate("");
              }
              if (e.target.value.includes("At")) {
                setSearch("");
              }
              setSearchType(e.target.value);
            }}
          >
            <option value={""} disabled>
              선택
            </option>
            {typeSearch.map((item) => (
              <option value={item.value} key={item.text}>
                {item.text}
              </option>
            ))}
          </select>
          {searchType !== "startAt" && searchType !== "endAt" ? (
            <input
              type="search"
              id="search"
              // placeholder="Searching for..."
              className="bg-none m0-top w-100"
              style={{ fontWeight: "500", height: "40px" }}
              onChange={(e) => setSearch(e.target.value)}
            />
          ) : (
            <ReactDatePicker
              selected={date}
              onChange={(value) => setDate(value)}
              dateFormat="yyyy-MM-dd"
              // minDate={new Date()}
            />
          )}
          <button
            className="py-1 bg-button-search"
            style={{
              borderRadius: "8px",
              height: "40px",
              width: "100px",
              minWidth: "100px"
            }}
            onClick={() => handleSearchButton()}
          >
            검색
          </button>
        </div>
      </div>
    </>
  );
};

export const CouponList = () => {
  const [data, setData] = useState([]);

  const { coupon, total } = useSelector((state) => state.coupon);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    type: "",
    value: "",
    date: ""
  });

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const fetchData = useCallback(() => {
    const payload = {
      [search.type]:
        search.type !== "title"
          ? search.date
            ? moment(search.date).local().format("YYYY-MM-DD")
            : ""
          : search.value
    };
    dispatch(getAllCoupon(payload));
  }, [dispatch, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setData(coupon);
  }, [coupon]);

  const couponTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "쿠폰_id",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.couponId}</span>
      )
    },
    {
      Header: "쿠폰 명",
      Cell: ({ row }) => <span>{row?.couponTitle}</span>
    },
    {
      Header: "쿠폰 내용",
      tdClass: "colContentCoupon",
      Cell: ({ row }) => (
        <span className="truncate-line-2">{row?.description}</span>
      )
    },
    {
      Header: "적용 시작일",
      Cell: ({ row }) => (
        <span className="">
          {row?.startAt && moment(row.startAt).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: "적용 종료일",
      Cell: ({ row }) => (
        <span className="">
          {row?.endAt && moment(row.endAt).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: "수정",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenEdit(row)}
        >
          수정
        </button>
      )
    },
    {
      Header: "삭제",
      Cell: ({ row }) => (
        <span>
          <button
            className="py-1 text-decoration-underline"
            // style={{ borderRadius: "8px", backgroundColor: "#E0F0FF" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => handleDelete(row?.couponId)}
          >
            삭제
          </button>
        </span>
      )
    },
    {
      Header: "발급",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenIssue(row)}
        >
          발급
        </button>
      )
    }
  ];

  const handleDelete = async (id) => {
    try {
      const data = await confirm({
        title: "쿠폰 삭제",
        text: "해당 쿠폰을 삭제하시겠습니까?",
        confirm: "확인",
        cancle: "취소"
      });
      const yes = data?.isConfirmed;
      if (yes) {
        dispatch(deleteCoupon(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenAdd = () => {
    dispatch(openDialog({ type: "couponDialog", data: null }));
  };

  const handleOpenEdit = (row) => {
    dispatch(openDialog({ type: "couponDialog", data: row }));
  };

  const handleOpenIssue = (row) => {
    dispatch(openDialog({ type: "couponIssue", data: row }));
  };

  const handleSearch = (type, value, date) => {
    if (
      type !== search.type ||
      value !== search.value ||
      date !== search.date
    ) {
      setPage(0);
    }

    console.log("date", date);
    setSearch({
      type: type,
      value: value,
      date: date || ""
    });
  };

  return (
    <div className="userTable">
      <div
        className="title text-capitalized fw-600"
        style={{ color: "#686b71" }}
      >
        쿠폰 등록
      </div>

      <div className="betBox mb-3">
        <div className="col-md-8 col-lg-7 inputData">
          <label>검색</label>
          <SearchingWithSelect handleSearch={handleSearch} />
        </div>
        <Button
          className={`d-flex align-items-center bg-button p-10 text-white m10-bottom ms-auto mt-auto`}
          style={{ marginLeft: "20px", height: "40px" }}
          text={`등록`}
          // bIcon={`fa-solid fa-user-plus`}
          onClick={() => handleOpenAdd()}
        />
      </div>
      <div>
        <Table
          data={data}
          mapData={couponTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"server"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
      {dialogue && dialogueType === "couponDialog" && (
        <CouponAddModal refetch={fetchData} />
      )}
      {dialogue && dialogueType === "couponIssue" && (
        <IssueCouponModal setData={setData} data={data} />
      )}
    </div>
  );
};
