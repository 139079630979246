import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirm } from "../../../util/Alert";
import Title from "../../extras/Title";
import Pagination from "../../extras/Pagination";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "./../../extras/Table";
import moment from "moment";
import CSDetailModal from "./CSDetailModal";
import { getAllCs } from "../../../redux/slice/csSlice";
import { useCallback } from "react";
import { CsInquiryStatus, CsInquiryType } from "../../../util/constants";
import { useNavigate } from "react-router-dom";

const typeSearch = [
  {
    value: "title",
    text: "제목"
  },
  {
    value: "detail",
    text: "내용"
  },
  {
    value: "type",
    text: "카테고리"
  }
];

export const SearchingWithSelect = (props) => {
  const [isDisable, setDisable] = useState(true);
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const { handleSearch } = props;

  const handleSearchButton = () => {
    handleSearch(search);
  };

  return (
    <>
      <div className="">
        <div className="inputData inputDataSearch d-flex gap-4">
          <input
            type="search"
            id="search"
            // placeholder="Searching for..."
            className="bg-none m0-top w-100"
            style={{ fontWeight: "500", height: "40px" }}
            value="이름"
            disabled
          />
          <input
            type="search"
            id="search"
            // placeholder="Searching for..."
            className="bg-none m0-top w-100"
            style={{ fontWeight: "500", height: "40px" }}
            onChange={(e) => {
              setSearch(e.target.value);
              setDisable(false);
            }}
          />
          <button
            className="py-1 bg-button-search disabled"
            style={{
              borderRadius: "8px",
              height: "40px",
              width: "100px",
              minWidth: "100px"
            }}
            disabled={isDisable}
            onClick={() => handleSearchButton()}
          >
            검색
          </button>
        </div>
      </div>
    </>
  );
};

export const CSList = () => {
  const [data, setData] = useState([]);

  const { csList, total } = useSelector((state) => state.cs);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const fetchData = useCallback(() => {
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
      searchText: search
    };
    dispatch(getAllCs(payload));
  }, [dispatch, page, rowsPerPage, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setData(csList);
  }, [csList]);

  const handleInfo = (row) => {
    navigate("/admin/user/userProfile", {
      state: {
        row
      }
    });
  };

  const csTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "접수 일시",
      Cell: ({ row }) => (
        <span>
          {row?.submittedAt && moment(row.submittedAt).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: "접수 매장",
      Cell: ({ row }) => <span>{row?.storeName}</span>
    },
    {
      Header: "문의 유형",
      Cell: ({ row }) => (
        <span className="truncate-line-2">
          {row?.inquiryType ? CsInquiryType[row?.inquiryType] : ""}
        </span>
      )
    },

    {
      Header: "이름",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleInfo(row?.sender)}
        >
          {row?.sender?.fullname}
        </button>
      )
    },
    {
      Header: "번호",
      Cell: ({ row }) => (
        <span className="truncate-line-2">{row?.sender?.phoneNumber}</span>
      )
    },
    {
      Header: "수신 이메일",
      Cell: ({ row }) => (
        <span className="truncate-line-2">{row?.recipientEmail}</span>
      )
    },
    {
      Header: "상태",
      Cell: ({ row }) => (
        <span className="truncate-line-2">
          {row?.status ? CsInquiryStatus[row?.status] : ""}
        </span>
      )
    },
    {
      Header: "상세 보기",
      Cell: ({ row }) => (
        <span className="">
          <button
            className="py-1 text-decoration-underline"
            // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => handleOpenDetail(row)}
          >
            상세 보기
          </button>
        </span>
      )
    }
  ];

  const handleDelete = async (id) => {
    try {
      const data = await confirm({
        title: "FAQ 삭제",
        text: "해당 FAQ를 삭제하시겠습니까?",
        confirm: "확인",
        cancle: "취소"
      });
      const yes = data?.isConfirmed;
      console.log("yes", yes);
      if (yes) {
        // dispatch(salonDelete(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenDetail = (row) => {
    dispatch(openDialog({ type: "csDetail", data: row }));
  };

  const handleSearch = (text) => {
    if (text !== search) {
      setPage(0);
    }
    setSearch(text);
  };

  return (
    <div className="userTable">
      <Title name="CS 리스트" />

      <div className="betBox mb-3">
        <div className="col-md-8 col-lg-7 inputData">
          <label>검색</label>
          <SearchingWithSelect handleSearch={handleSearch} />
        </div>
      </div>
      <div>
        <Table
          data={data}
          mapData={csTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"server"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
      {dialogue && dialogueType === "csDetail" && (
        <CSDetailModal refetch={fetchData} />
      )}
    </div>
  );
};
