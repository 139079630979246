/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../component/api/axiosApi";
import { DangerRight, Success } from "../../component/api/toastServices";
import { SuccessStatusCode } from "../../util/constants";
import { genQueryString } from "./../../util/fuction";

const initialState = {
  isLoading: false,
  isSkeleton: false,
  admin: [],
  total: null
};

export const getAllAdmin = createAsyncThunk(
  "admin/admin/getAll",
  async (payload) => {
    return apiInstance.get(genQueryString(`/admins`, payload));
  }
);

export const addAdmin = createAsyncThunk(
  "admin/admin/create",
  async (payload) => {
    return apiInstance.post(`admins`, payload);
  }
);

export const checkEmailAdmin = createAsyncThunk(
  "admin/check/email",
  async (payload) => {
    return apiInstance.post(`admins/check-email`, payload);
  }
);

export const updateAdmin = createAsyncThunk(
  "admin/admin/update",
  async (payload) => {
    return apiInstance.put(`admins/${payload.id}`, payload.body);
  }
);

export const deleteAdmin = createAsyncThunk(
  "admin/admin/delete",
  async (id) => {
    const res = await apiInstance.delete(`admins/${id}`);
    return {
      data: res,
      id
    };
  }
);

const adminSlice = createSlice({
  name: "adminSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllAdmin.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllAdmin.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.admin = action.payload.data;
      state.total = action.payload.count;
    });

    builder.addCase(getAllAdmin.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(addAdmin.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addAdmin.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        state.total += 1;
        Success("관리자가 생성되었습니다.");
      }
      state.isLoading = false;
    });

    builder.addCase(addAdmin.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateAdmin.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateAdmin.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        Success("관리자가 수정되었습니다");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(updateAdmin.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteAdmin.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAdmin.fulfilled, (state, action) => {
      if (action?.payload?.data?.statusCode === SuccessStatusCode) {
        state.admin = state.admin.filter(
          (item) => item?.id !== action?.payload?.id
        );

        state.total -= 1;
        Success("관리자가 삭제되었습니다.");
      }
      state.isLoading = false;
    });
    builder.addCase(deleteAdmin.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});
export default adminSlice.reducer;
