import React, { useEffect, useMemo, useState } from "react";
import Pagination from "../../extras/Pagination";
import Table from "../../extras/Table";
import Analytics from "../../extras/Analytics";
import Title from "../../extras/Title";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getSalonBookings } from "../../../redux/slice/salonSlice";
import CancleDetails from "../booking/CancleDetails";
import CancelBookingDialog from "../booking/CancelBookingDialog";
import DatePeriod from "../../extras/DatePeriod";
import Button from "../../extras/Button";
import moment from "moment";
import * as XLSX from "xlsx";
import { apiInstance } from "../../api/axiosApi";

const bookingType = [
  { name: "전체", value: "" },
  { name: "예정", value: "scheduled" },
  { name: "완료", value: "completed" },
  { name: "취소", value: "cancelled" }
];

const mapMethod = {
  card: "결제 완료",
  in_store: "현장결제"
};

const SalonBooking = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const { booking = [], total = 0 } = useSelector((state) => state.salon);

  const state = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [type, setType] = useState("ALL");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [date, setDate] = useState({
    startDate: "ALL",
    endDate: "ALL"
  });
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");

  const payload = useMemo(() => {
    return {
      page: page + 1,
      pageSize: rowsPerPage,
      storeId: state?.state?.data?.storeId,
      status: type === "ALL" ? "" : type,
      from: date.startDate === "ALL" ? "" : date.startDate,
      to: date.endDate === "ALL" ? "" : date.endDate + " 23:59:59"
    };
  }, [page, rowsPerPage, date, state?.state?.data?.storeId, type]);

  useEffect(() => {
    dispatch(getSalonBookings({ ...payload }));
  }, [dispatch, payload]);

  useEffect(() => {
    setData(booking);
  }, [booking]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleChangeType = (e) => {
    const value = e.target.value;
    if (value !== type) {
      setPage(0);
    }
    setType(value);
  };

  const handleInfo = (row) => {
    navigate("/admin/user/userProfile", {
      state: {
        row
      }
    });
  };

  const bookingTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{total - ((page + 1 - 1) * rowsPerPage + index)}</span>
      )
    },
    ...(!state?.state?.data?.storeId
      ? [
          {
            Header: "예약 매장",
            Cell: ({ row }) => (
              <span className="text-capitalize">{row?.storeName}</span>
            )
          }
        ]
      : []),
    {
      Header: "예약자 닉네임",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ borderRadius: "8px", backgroundColor: "#E0F0FF" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleInfo(row?.buyer)}
        >
          {row?.buyer?.nickname}
        </button>
      )
    },
    {
      Header: "바텐더 닉네임",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ borderRadius: "8px", backgroundColor: "#E0F0FF" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleInfo(row?.bartender)}
        >
          {row?.bartender?.nickname}
        </button>
      )
    },
    {
      Header: "주문 메뉴",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.menuName}</span>
      )
    },
    {
      Header: "가격",
      Cell: ({ row }) => <span className="text-capitalize">{row?.price}원</span>
    },
    {
      Header: "예약 번호",
      Cell: ({ row }) => <span className="text-capitalize">{row?.id}</span>
    },
    {
      Header: state?.state?.data?.storeId ? `예약 신청일` : `예약 신청일`,
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {state?.state?.data?.storeId
            ? row?.createdAt && moment(row.createdAt).format("YYYY-MM-DD")
            : row?.createdAt && moment(row.createdAt).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: state?.state?.row?.storeId ? "예약 시간" : "예약 시간",
      Cell: ({ row }) => (
        <>
          <p className="text-uppercase">
            {/* {state?.state?.row?.storeId
              ? row?.reservationDate &&
                moment(row.reservationDate).format("YYYY-MM-DD")
              : ""} */}
            {row?.reservationDate &&
              moment(row.reservationDate).format("YYYY-MM-DD")}
          </p>
          {row?.reservationHours.map((item) => (
            <p className="text-uppercase" key={item}>
              {moment(item, "hh:mm").format("hh:mm a")}{" "}
            </p>
          ))}
        </>
      )
    },
    {
      Header: "상태",
      Cell: ({ row }) =>
        row?.status === "scheduled" ? (
          <button
            className="text-white m5-right p12-x p4-y fs-12 br-5"
            style={{ backgroundColor: "#00b0f0" }}
            onClick={() => handleOpenCancelDialogue(row)}
          >
            예정
          </button>
        ) : row?.status === "cancelled" ? (
          <button
            className="text-white m5-right p12-x p4-y fs-12 br-5"
            style={{ backgroundColor: "#ff7512", cursor: "pointer" }}
            onClick={() => handleOpenDialogue(row)}
          >
            취소
          </button>
        ) : (
          <button
            className="text-white m5-right p12-x p4-y fs-12 br-5"
            style={{ backgroundColor: "#00b050" }}
          >
            완료
          </button>
        )
    },
    {
      Header: "결제 여부",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.paymentMethod ? mapMethod[row?.paymentMethod] : ""}
        </span>
      )
    }
  ];

  const handleOpenDialogue = (row) => {
    dispatch(openDialog({ type: "cancel", data: row }));
  };

  const handleOpenCancelDialogue = (row) => {
    dispatch(openDialog({ type: "cancelBooking", data: row }));
  };

  const handleDownloadExcel = async () => {
    const res = await apiInstance.get(
      `admin/reservations?${payload.type ? `&status=${payload.type}` : ""}${
        payload.startDate ? `&from=${payload.startDate}` : ""
      }${payload.endDate ? `&to=${payload.endDate}` : ""}${
        payload.storeId ? `&storeId=${payload.storeId}` : ""
      }`
    );
    console.log("res", res);
    const total = res.count;
    const flattenedData = (res?.data || []).map((item, index) => ({
      No: total - index,
      ...(!state?.state?.data?.storeId
        ? {
            "예약 매장": item?.storeName
          }
        : {}),
      "예약자 닉네임": item?.buyer?.nickname,
      "바텐더 닉네임": item?.bartender?.nickname,
      "주문 메뉴": item?.menuName,
      가격: item?.price,
      "예약 번호": item?.id,
      ...(!state?.state?.data?.storeId
        ? {
            "예약 신청일":
              item?.createdAt && moment(item.createdAt).format("YYYY-MM-DD")
          }
        : {
            "예약 신청일":
              item?.createdAt && moment(item.createdAt).format("YYYY-MM-DD")
          }),
      ...(!state?.state?.data?.storeId
        ? {
            "예약 시간":
              item?.reservationDate &&
              moment(item.reservationDate).format("YYYY-MM-DD") +
                " - " +
                item?.reservationHours.map((hours) =>
                  moment(hours, "hh:mm").format("hh:mm a")
                )
          }
        : {
            "예약 시간":
              item?.reservationDate &&
              moment(item.reservationDate).format("YYYY-MM-DD") +
                " - " +
                item?.reservationHours.map((hours) =>
                  moment(hours, "hh:mm").format("hh:mm a")
                )
          }),
      상태:
        item?.status === "scheduled"
          ? "예정"
          : item?.status === "cancelled"
          ? "취소"
          : item?.status === "completed"
          ? "완료"
          : "",
      "결제 여부": item?.paymentMethod ? mapMethod[item?.paymentMethod] : ""
    }));

    const headers = [
      "No",
      ...(!state?.state?.data?.storeId ? ["예약 매장"] : []),
      "예약자 닉네임",
      "바텐더 닉네임",
      "주문 메뉴",
      "가격",
      "예약 번호",
      "예약 신청일",
      "예약 시간",
      "상태",
      "결제 여부"
    ];
    const ws = XLSX.utils.json_to_sheet(flattenedData);

    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let col = 0; col < headers.length; col++) {
      const cellAddress = { r: range.s.r, c: col };
      const cell = { v: headers[col], t: "s" };
      ws[XLSX.utils.encode_cell(cellAddress)] = cell;
    }

    const wb = XLSX.utils.book_new();
    const sheetName = state?.state?.data?.storeName
      ? `스토어바 ${state ? state?.state?.data?.storeName : ""}역점 예약 현황`
      : "예약 현황";
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, sheetName + ".xlsx");
  };

  const handleChangeDate = (start, end) => {
    if (start !== date.startDate || end !== date.endDate) {
      setPage(0);
    }
    setDate({
      startDate: start,
      endDate: end
    });
  };

  return (
    <div className="mainBooking">
      <Title
        name={
          state?.state?.data?.storeName
            ? `스토어바 ${
                state ? state?.state?.data?.storeName : ""
              }역점 예약 현황`
            : "예약 현황"
        }
      />

      <div className="d-flex align-items-center mb-4">
        <div className="inputData d-flex flex-column">
          <label className="styleForTitle" htmlFor="bookingType">
            예약 상태
          </label>
          <select
            name="bookingType"
            className="rounded-2 fw-bold inputTypeSearch"
            style={{
              height: "40px",
              width: "100px",
              minWidth: "100px"
            }}
            id="bookingType"
            value={type}
            onChange={(e) => {
              handleChangeType(e);
            }}
          >
            {bookingType?.map((data) => {
              return (
                <option value={data?.value} key={data?.name}>
                  {data?.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="ms-4">
          <div className="inputData" style={{ marginTop: "-2px" }}>
            <label>검색 기간</label>
          </div>
          <div style={{ marginTop: "5px" }}>
            <DatePeriod
              analyticsStartDate={startDate}
              analyticsStartEnd={endDate}
              analyticsStartDateSet={setStartDate}
              analyticsStartEndSet={setEndDate}
              analyticsDateSet={handleChangeDate}
            />
          </div>
        </div>
        <Button
          className={`bg-button-excel d-flex align-items-center justify-content-center p-10 text-black  ms-auto mt-auto`}
          style={{ marginLeft: "20px", height: "40px" }}
          text={`다운로드`}
          // bIcon={`fa-solid fa-user-plus`}
          onClick={() => handleDownloadExcel()}
        />
      </div>

      <div>
        <Table
          data={data}
          mapData={bookingTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"server"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
      {dialogue && dialogueType === "cancel" && <CancleDetails />}
      {dialogue && dialogueType === "cancelBooking" && (
        <CancelBookingDialog
          refetch={() => {
            dispatch(getSalonBookings({ ...payload }));
          }}
        />
      )}
    </div>
  );
};

export default SalonBooking;
