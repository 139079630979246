/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../component/api/axiosApi";
import { DangerRight, Success } from "../../component/api/toastServices";
import { SuccessStatusCode } from "../../util/constants";
import { genQueryString } from "../../util/fuction";

const initialState = {
  isLoading: false,
  isSkeleton: false,
  leader: [],
  leaderMember: [],
  totalMember: null,
  total: null
};

export const getAllLeader = createAsyncThunk(
  "admin/leader/getAll",
  async (payload) => {
    return apiInstance.get(genQueryString(`admins/leader`, payload));
  }
);

export const getAllLeaderMember = createAsyncThunk(
  "admin/leader/member",
  async (id) => {
    return apiInstance.get(`admins/leader/${id}/member`);
  }
);

export const getAllLeaderMembers = createAsyncThunk(
  "admin/leader/members",
  async (id) => {
    return apiInstance.get(`admins/leader/${id}/member`);
  }
);

export const addLeader = createAsyncThunk(
  "admin/leader/create",
  async (payload) => {
    return apiInstance.post(`admins/leader/create`, payload);
  }
);

export const updateLeader = createAsyncThunk(
  "admin/leader/update",
  async (payload) => {
    return apiInstance.put(`admins/leader/${payload.id}`, payload.body);
  }
);

export const deleteLeader = createAsyncThunk(
  "admin/leader/delete",
  async (id) => {
    const res = await apiInstance.delete(`admins/leader/${id}`);
    return {
      data: res,
      id
    };
  }
);

const leaderSlice = createSlice({
  name: "leaderSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllLeader.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllLeader.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.leader = action.payload.data;
      state.total = action.payload.count;
    });

    builder.addCase(getAllLeader.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getAllLeaderMember.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllLeaderMember.fulfilled, (state, action) => {
      console.log("action.payload", action.payload);
      state.isSkeleton = false;
      state.leaderMember = action.payload?.data || [];
      state.totalMember = action.payload?.count || 0;
    });

    builder.addCase(getAllLeaderMember.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(addLeader.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addLeader.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        state.total += 1;
        Success("팀장 추가되었습니다.");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(addLeader.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateLeader.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateLeader.fulfilled, (state, action) => {
      if (action?.payload?.statusCode === SuccessStatusCode) {
        Success("팀장 수정되었습니다.");
      } else {
        DangerRight(action?.payload?.message);
      }
      state.isLoading = false;
    });

    builder.addCase(updateLeader.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteLeader.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteLeader.fulfilled, (state, action) => {
      if (action?.payload?.data?.statusCode === SuccessStatusCode) {
        state.leader = state.leader.filter(
          (item) => item?.leaderId !== action?.payload?.id
        );

        state.total -= 1;
        Success("팀장 삭제되었습니다.");
      }
      state.isLoading = false;
    });
    builder.addCase(deleteLeader.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});
export default leaderSlice.reducer;
